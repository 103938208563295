<template>
 <div>
  <Header></Header>

  <section class="pb-3">
   <div class="container pt-2">
    <h1 class="subtitle-policy text-center">POLÍTICA DE PRIVACIDADE</h1>

    <p class="text-desc text-center mt-1">GRUPO DAHRUJ</p>

    <div class="text-holder mt-2">
     <span class="title">1. Introdução</span>
     <p>
      A Política de Privacidade é o documento por meio do qual o Grupo Dahruj
      explica aos titulares as práticas e os processos adotados para tornar
      transparente essa relação.
     </p>
     <p>
      Basicamente, ela informa ao titular todos os direitos, garantias, formas
      de uso, dados recolhidos, processamento e descarte dessas informações
      pessoais.
     </p>
     <p>
      A proteção de dados ganhou especial relevância após a entrada em vigor do
      Regulamento Geral de Proteção de Dados da União Europeia (GDPR). Seguindo
      a mesma linha, o Brasil adotou uma legislação específica para tratar do
      tema, qual seja, a Lei n° 13.709/2018, mais conhecida como Lei Geral de
      Proteção de Dados (LGPD).
     </p>
     <p>
      Já em fevereiro do ano de 2022, foi promulgada a Emenda Constitucional
      115/2022 (EC 115/2022). A referida emenda incluiu no rol de direitos
      fundamentais previstos no artigo 5º da Constituição Federal o direito à
      proteção dos dados pessoais, inclusive nos meios digitais.
     </p>
     <p>
      A presente Política de Privacidade contém informações a respeito do modo
      como são tratados, total ou parcialmente, de forma automatizada ou não, os
      dados pessoais pelo Grupo Dahruj. Essa Política tem por finalidade
      esclarecer aos interessados acerca dos tipos de dados que são tratados,
      dos motivos da coleta e da forma como o titular poderá atualizar,
      gerenciar ou excluir estas informações.
     </p>
     <p>
      Esta Política de Privacidade poderá ser atualizada em decorrência de
      eventual atualização normativa ou, ainda, por mera liberalidade, razão
      pela qual se convida o titular a consultar periodicamente esta seção.
     </p>
     <p>
      Este documento foi elaborado em conformidade com a Lei Geral de Proteção
      de Dados Pessoais (Lei n° 13.709/2018), Marco Civil da Internet (Lei n°
      12.965/2014) e demais legislações vigentes à época.
     </p>
    </div>

    <div class="text-holder mt-2">
     <span class="title">2. Principais definições</span>
     <p class="no-indent">
      <b>CF :</b> Constituição da República Federativa do Brasil de 1988,
      disponível no link
      <a
       class="link"
       target="_blank"
       href="http://www.planalto.gov.br/ccivil_03/constituicao/constituicao.htm"
       >http://www.planalto.gov.br/ccivil_03/constituicao/constituicao.htm</a
      >
     </p>
     <p class="no-indent">
      <b>LGPD :</b> Lei Geral de Proteção de Dados (Lei 13.709/18), disponível
      no link
      <a
       class="link"
       target="_blank"
       href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm"
       >http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm</a
      >
     </p>
     <p class="no-indent">
      <b>Direito Fundamental :</b> São os direitos previstos no rol do artigo 5º
      da CF. O direito à proteção de dados é um direito fundamental, o que
      significa que este é entendido como primordial à dignidade da pessoa
      humana e que ele deve ser garantido a todos os brasileiros e estrangeiros
      que se encontram no Brasil (embora a CF cite no caput do artigo 5º que são
      garantidos os direitos ali elencados apenas aos
      <b>estrangeiros residentes</b> no país, o entendimento jurisprudencial e
      doutrinário é de que tais direitos são estendidos aos
      <b>não residentes</b> também, tais como turistas).
     </p>
     <p class="no-indent">
      <b>Dado Pessoal :</b> A Lei Geral de Proteção de Dados - LGPD, define em
      seu artigo 5º, inciso I, que dado pessoal é todo aquele dado que se refira
      a uma pessoa natural identificada ou identificável. Essa definição
      compreende todo aquele dado que permite identificar uma pessoa física
      diretamente ou a combinação de dados que podem identificar um determinado
      titular de dados de forma inequívoca.
     </p>
     <p class="no-indent">
      <b>Dados Pessoal Sensível :</b> É o dado pessoal sobre origem racial ou
      étnica, convicção religiosa, opinião política, filiação a sindicato ou a
      organização de caráter religioso, filosófico ou político, dado referente à
      saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a
      uma pessoa natural.
     </p>
     <p class="no-indent">
      <b>Titular :</b> O titular dos dados é a pessoa natural à qual se referem
      os dados pessoais objeto do tratamento.
     </p>
     <p class="no-indent">
      <b>Controlador :</b> É a pessoa natural ou jurídica, de direito público ou
      privado, a quem compete as decisões sobre como o tratamento dos dados
      pessoais.
     </p>
     <p class="no-indent">
      <b>Operador :</b> É a pessoa natural ou jurídica, de direito público ou
      privado que realiza o tratamento em nome do controlador.
     </p>
     <p class="no-indent">
      <b>Encarregado/DPO :</b> É a pessoa que atua na organização como um canal
      de ligação e comunicação, entre o controlador, os titulares de dados, e a
      Autoridade Nacional de Proteção de Dados (ANPD).
     </p>
     <p class="no-indent">
      <b>ANPD :</b> Autoridade Nacional de Proteção de Dados é o órgão da
      administração pública responsável por zelar, implementar e fiscalizar o
      cumprimento desta Lei em todo o território nacional.
     </p>
    </div>
    <div class="text-holder mt-2">
     <span class="title">3. O que são dados pessoais?</span>
     <p>
      A Lei Geral de Proteção de Dados - LGPD, define em seu artigo 5º, inciso
      I, que dado pessoal é todo aquele dado que se refira a uma pessoa natural
      identificada ou identificável. Essa definição compreende todo aquele dado
      que permite identificar uma pessoa diretamente ou a combinação de dados
      que podem identificar esta pessoa de forma inequívoca.
     </p>
    </div>

    <div class="text-holder mt-2">
     <span class="title">4. Direitos dos titulares</span>
     <p>
      O Grupo Dahruj respeita os direitos dos titulares dos dados conferidos
      pela LGPD, em seu artigo 18. Sendo eles:
     </p>
     <ul>
      <li>
       Direito de confirmação e acesso: é o direito do titular obter a
       confirmação de que os dados pessoais que lhe digam respeito são ou não
       objeto de tratamento e, se for o caso, o direito de acessar os seus dados
       pessoais;
      </li>
      <li>
       Direito de retificação: é o direito do titular de obter, do controlador,
       sem demora injustificada, a retificação dos dados pessoais inexatos que
       lhe digam respeito;
      </li>
      <li>
       Direito à eliminação dos dados (direito ao esquecimento): é o direito do
       titular de ter seus dados apagados da base de dados do controlador;
      </li>
      <li>
       Direito à limitação do tratamento dos dados: é o direito do titular de
       limitar o tratamento de seus dados pessoais, podendo obtê-la quando
       contesta a exatidão dos dados, quando o tratamento for ilícito, quando o
       controlador não precisar mais dos dados para as finalidades propostas e
       quando tiver se oposto ao tratamento dos dados e em caso de tratamento de
       dados desnecessários;
      </li>
      <li>
       Direito de oposição: é o direito do titular de, a qualquer momento, se
       opor por motivos relacionados com a sua situação particular, ao
       tratamento de dados pessoais que lhe digam respeito, podendo se opor,
       ainda, ao uso de seus dados pessoais para definição de perfil de
       marketing;
      </li>
      <li>
       Direito de portabilidade dos dados: é o direito do titular de receber os
       dados pessoais que lhe digam respeito e que tenha fornecido ao
       controlador, num formato estruturado, de uso corrente e de leitura
       automática, e o direito de transmitir esses dados a outro controlador;
      </li>
      <li>
       Direito de não ser submetido a decisões automatizadas: é o direito do
       titular de não ficar sujeito a nenhuma decisão tomada exclusivamente com
       base no tratamento automatizado, incluindo a definição de perfis, que
       produza efeitos na sua esfera jurídica ou que o afete significativamente
       de forma similar;
      </li>
     </ul>
     <p class="mt-1">
      O Grupo Dahruj se compromete em cumprir as determinações previstas na
      LGPD, em respeito aos seguintes princípios, bem como o princípio da
      boa-fé:
     </p>
     <ul>
      <li>
       Os dados pessoais do titular serão para propósitos legítimos,
       específicos, explícitos e informados ao titular, sem possibilidade de
       tratamento posterior de forma incompatível com essas finalidades
       (Princípio da Finalidade);
      </li>
      <li>
       Os dados pessoais do titular serão tratados de forma adequada às
       finalidades informadas ao titular, de acordo com o contexto de tratamento
       (Princípio da Adequação);
      </li>
      <li>
       Os dados pessoais do titular serão tratados de forma pertinente e
       limitada às necessidades do objetivo para os quais eles são processados
       (Princípio da Necessidade);
      </li>
      <li>
       Os dados pessoais do titular serão tratados e garantidos, aos titulares,
       consulta facilitada e gratuita sobre a forma e duração de tratamento, bem
       como sobre a integralidade dos seus dados (Princípio do Livre Acesso);
      </li>
      <li>
       Os dados pessoais do titular serão exatos e atualizados sempre que
       necessário, de maneira que dados inexatos sejam apagados ou retificados
       quando possível (Princípio da Qualidade dos Dados);
      </li>
      <li>
       Os dados pessoais dos titulares serão tratados e garantidos, aos
       titulares, informações claras, precisas e facilmente acessíveis,
       inclusive sobre os agentes de tratamento, ressalvados os segredos
       comercial e industrial (Princípio da Transparência);
      </li>
      <li>
       Os dados pessoais do titular serão tratados de forma segura, protegidos
       do tratamento não autorizado ou ilícito e contra a sua perda, destruição
       ou danificação acidental, adotando as medidas técnicas ou organizativas
       adequadas (Princípio da Segurança);
      </li>
      <li>
       Os dados pessoais do titular serão tratados, utilizando-se, o
       controlador, de todas as medidas para prevenir a ocorrência de danos em
       virtude desse tratamento (Princípio da Prevenção);
      </li>
      <li>
       Os dados pessoais do titular NÃO serão tratados para fins
       discriminatórios, ilícitos ou abusivos (Princípio da Não Discriminação);
      </li>
      <li>
       Os dados pessoais do titular serão tratados, pelo controlador, de forma
       capaz de demonstrar as medidas adotadas a fim de comprovar a observância
       e o cumprimento das normas pertinentes à proteção de dados (Princípio da
       Responsabilização e Prestação de Contas).
      </li>
     </ul>
     <p class="mt-1">
      O titular poderá exercer os seus direitos, por meio de comunicação
      escrita, especificando os direitos que deseja exercer perante o
      controlador.
     </p>
     <p>
      O pedido deverá ser enviado ao e-mail
      <a class="link" :href="'mailto:dpo@grupodahruj.com.br'"
       >dpo@grupodahruj.com.br</a
      >.
     </p>
     <p>
      O titular dos dados será respondido sobre suas solicitações nos termos e
      dentro do prazo legal, conforme disposições do artigo 19 da LGPD.
     </p>
    </div>

    <div class="text-holder mt-2">
     <span class="title">5. Dados pessoais processados pelo grupo Dahruj</span>
     <p>
      O Grupo Dahruj trata dados pessoais de quem é ou foi cliente ou teve algum
      relacionamento com a empresa, procurador, empregado, sócio de algum
      cliente, empresa ou entidade com a qual o Grupo Dahruj se relaciona ou
      pretende se relacionar.
     </p>
     <p>
      Os dados pessoais tratados variam de acordo com as finalidades de uso,
      inclusive as indicadas nessa Política de Privacidade e com as atividades
      realizadas.
     </p>
     <p>
      O Grupo Dahruj não processa dados de titulares menores de idade ou
      adolescentes, mas se acaso vier a fazê-lo, isto se dará em caráter
      excepcional e desde que tenha uma base legal lícita atribuída, em
      conformidade com a LGPD.
     </p>
     <p>
      O Grupo Dahruj processa dados pessoais sensíveis, em conformidade com o
      artigo 11 da LGPD, desde que haja consentimento do titular ou, em não
      havendo consentimento, nos casos em que o tratamento for indispensável
      para cumprimento de obrigação legal ou regulatória pelo controlador;
      exercício regular de direitos, inclusive em contrato e em processo
      judicial, administrativo e arbitral; garantia da prevenção à fraude e à
      segurança do titular, nos processos de identificação e autenticação de
      cadastro em sistemas eletrônicos, resguardados os direitos mencionados no
      art. 9º desta Lei e exceto no caso de prevalecerem direitos e liberdades
      fundamentais do titular que exijam a proteção dos dados pessoais.
     </p>
    </div>

    <div class="text-holder mt-2">
     <span class="title"
      >6. Fundamento jurídico para tratamento dos dados pessoais
     </span>
     <p>
      Atendendo os requisitos de tratamento de dados disposto no artigo 7º, o
      Grupo Dahruj pode processar dados pessoais nas seguintes hipóteses:
     </p>
     <ul>
      <li>Mediante o fornecimento de consentimento pelo titular;</li>
      <li>
       Para o cumprimento de obrigação legal ou regulatória pelo controlador;
      </li>
      <li>
       Quando necessário para a execução de contrato ou de procedimentos
       preliminares relacionados a contrato do qual seja parte o titular, a
       pedido do titular dos dados;
      </li>
      <li>
       Para o exercício regular de direitos em processo judicial, administrativo
       ou arbitral, esse último nos termos da Lei nº 9.307, de 23 de setembro de
       1996 (Lei de Arbitragem);
      </li>
      <li>
       Para a proteção da vida ou da incolumidade física do titular ou de
       terceiro;
      </li>
      <li>
       Quando necessário para atender aos interesses legítimos do controlador ou
       de terceiro, exceto no caso de prevalecerem direitos e liberdades
       fundamentais do titular que exijam a proteção dos dados pessoais; ou
      </li>
      <li>
       Para a proteção do crédito, inclusive quanto ao disposto na legislação
       pertinente.
      </li>
     </ul>
     <p>
      Todos os processos operacionais que realizam tratamento de dados pessoais
      do Grupo Dahruj, atendem ao menos um requisito legal.
     </p>
    </div>

    <div class="text-holder mt-2">
     <span class="title">7. Segurança dos dados pessoais processados</span>
     <p>
      O Grupo Dahruj se compromete a aplicar todas as medidas técnicas e
      organizativas aptas a proteger os dados pessoais coletados de acessos não
      autorizados e de situações de destruição, perda, alteração, comunicação ou
      difusão de tais dados.
     </p>
     <p>
      Para a garantia da segurança, serão adotadas soluções que levem em
      consideração as técnicas adequadas, os custos de aplicação, a natureza, o
      âmbito, o contexto e as finalidades do tratamento e os riscos para os
      direitos e liberdades do titular.
     </p>
     <p>
      No entanto, o Grupo Dahruj se exime de responsabilidade por culpa
      exclusiva de terceiros e do titular.
     </p>
     <p>
      O Grupo Dahruj se compromete, ainda, a comunicar o titular em prazo
      adequado caso ocorra algum tipo de violação da segurança de seus dados
      pessoais que possa lhe causar um alto risco para seus direitos e
      liberdades pessoais.
     </p>
     <p>
      A violação de dados pessoais é uma violação de segurança que provoque, de
      modo acidental ou ilícito, a destruição, a perda, a divulgação ou o acesso
      não autorizado a dados pessoais transmitidos, conservados ou sujeitos a
      qualquer outro tipo de tratamento.
     </p>
     <p>
      Os dados pessoais armazenados são tratados garantindo a confidencialidade,
      a integridade e a disponibilidade das informações, dentro dos limites
      legais.
     </p>
    </div>

    <div class="text-holder mt-2">
     <span class="title">8. Transferências internacionais de dados</span>
     <p>
      O Grupo Dahruj trata as informações pessoais em território nacional e em
      países que possuem legislações semelhantes e equivalentes.
     </p>
     <p>
      Além disso, mantém cláusulas específicas, alinhadas com as leis e
      regulamentações brasileiras, para assegurar o correto tratamento.
     </p>
     <p>
      Quando o Grupo Dahruj realiza o tratamento de dados transfronteiriço
      garante o respeito aos direitos e liberdades dos titulares dos dados.
     </p>
    </div>

    <div class="text-holder mt-2">
     <span class="title"
      >9. Do responsável pelo tratamento dos dados pessoais - Controlador</span
     >
     <p>
      O controlador/responsável pelo tratamento dos dados pessoais do titular, é
      a pessoa física ou jurídica, a autoridade pública ou outro organismo que,
      individualmente ou em conjunto com outras, determina as finalidades e os
      meios de tratamento de dados pessoais.
     </p>
     <p>
      No presente caso, o responsável pelo tratamento dos dados pessoais
      coletados é a empresa Grupo Dahruj, que poderá ser contatada pelo e-mail:
      <a class="link" href="mailto:comite@grupodahruj.com.br"
       >comite@grupodahruj.com.br</a
      >.
     </p>
    </div>

    <div class="text-holder mt-2">
     <span class="title"
      >10. Do encarregado de proteção de dados (Data Protection Officer -
      DPO)</span
     >
     <p>
      O encarregado de proteção de dados (data protection officer – DPO) é a
      pessoa indicada pelo controlador e operador para atuar como canal de
      comunicação entre o controlador, os titulares dos dados e a Autoridade
      Nacional de Proteção de Dados (ANPD), conforme disposição do artigo 5º,
      VIII.
     </p>
     <p>
      No caso do Grupo Dahruj, o Encarregado de Proteção de Dados (Data
      Protection Officer – DPO) é Anderson Tavares Brito dos Santos, que poderá
      ser contatado através do e-mail:
      <a class="link" href="mailto:dpo@grupodahruj.com.br"
       >dpo@grupodahruj.com.br</a
      >.
     </p>
    </div>

    <div class="text-holder mt-2">
     <span class="title"
      >11. Das alterações para essa política de privacidade</span
     >
     <p>
      A presente Política de Privacidade foi atualizada pela última vez em
      06/04/2022.
     </p>
     <p>
      O Grupo Dahruj se reserva ao direito de modificar essa Política de
      Privacidade a qualquer momento, então, é recomendável que o titular a
      revise com frequência.
     </p>
     <p>
      As alterações e esclarecimentos vão surtir efeito imediatamente após a sua
      publicação no site da empresa.
     </p>
    </div>

    <div class="text-holder mt-2">
     <span class="title">12. Informações de contato</span>
     <p>
      Se persistirem dúvidas sobre a nossa Política de Privacidade, o Grupo
      Dahruj está disponível através dos contatos abaixo:
     </p>
     <ul>
      <li>
       Controlador:
       <a class="link" href="mailto:comite@grupodahruj.com.br"
        >comite@grupodahruj.com.br</a
       >
      </li>
      <li>
       DPO/ Encarregado dos Dados:
       <a class="link" href="mailto:dpo@grupodahruj.com.br"
        >dpo@grupodahruj.com.br</a
       >
      </li>
     </ul>
    </div>
   </div>
  </section>

  <Forms></Forms>

  <div class="clearfix"></div>

  <Footer />
 </div>
</template>
<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

export default {
 name: "Terms",
 components: {
  Header,
  Footer,
 },
};
</script>
<style scoped>
section {
 margin-top: 90px;
}

.container {
 border-top: 1px solid var(--red);
}

.text-holder {
 position: relative;
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
}

.text-holder .title {
 font-family: fontBold;
 font-size: 1.5em;
 text-transform: uppercase;
 text-align: center;
 margin-bottom: 15px;
}

.text-holder p {
 text-indent: 1.5em;
 margin-top: 0.3em;
 font-family: fontLight;
 font-size: 1.4em;
 font-weight: lighter;
}

.text-holder .no-indent {
 margin-top: 15px !important;
 text-indent: 0 !important;
}

.text-holder ul li {
 margin-left: 3em;
 text-indent: 1.5em;
 margin-top: 0.5em;
 font-family: fontLight;
 font-size: 1.4em;
 font-weight: lighter;
 list-style: initial;
}

.link {
 font-family: fontLight;
 font-size: 0.95em;
 font-weight: lighter;
}
</style>
