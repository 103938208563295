<template>
 <header>
  <div class="container">
   <a @click="scrollToElement('calc')" href="#/">
    <h1 class="logo">Rent a Car</h1>
   </a>
   <font-awesome-icon
    @click="openMenu = !openMenu"
    :icon="['fas', 'ellipsis-v']"
    class="icon-menu desktop-hidden tablet-hidden"
   />
   <transition
    enter-active-class="animated fadeInDown"
    leave-active-class="animated fadeOutUp"
   >
    <nav v-show="openMenu">
     <ul>
      <li @click="scrollToElement('#intro')">
       <a href="#/">INÍCIO</a>
      </li>
      <li @click="scrollToElement('#steps')">
       <a href="#/">COMO ASSINAR</a>
      </li>
      <li @click="scrollToElement('#offers')">
       <a href="#/">OFERTAS</a>
      </li>
      <li @click="scrollToElement('#about')">
       <a href="#/">SOBRE</a>
      </li>
      <li>
       <a href="#/perguntas-frequentes">FAQ</a>
      </li>
      <li class="desktop-hidden tablet-hidden">
       <router-link to="/politica-de-privacidade"
        >POLÍTICA DE PRIVACIDADE</router-link
       >
      </li>
      <li class="desktop-hidden tablet-hidden">
       <router-link to="/politica-de-cookies">POLÍTICAS DE COOKIES</router-link>
      </li>
      <li class="desktop-hidden tablet-hidden">
       <router-link to="/politica-de-seguranca-da-informacao"
        >POLÍTICAS DE SEGURANÇA</router-link
       >
      </li>
      <li class="desktop-hidden tablet-hidden">
       <router-link to="/codigo-etica">CÓDIGO DE ÉTICA</router-link>
      </li>
      <li class="desktop-hidden tablet-hidden">
       <router-link to="/politica-anticorrupcao"
        >POLÍTICA ANTICORRUPÇÃO</router-link
       >
      </li>
      <li>
       <a href="..//pdf/proposta-modelo.pdf" target="_blank">
        <button class="offers-all contract">
         <font-awesome-icon :icon="['far', 'file-alt']" />
         <p>CONTRATO</p>
        </button>
       </a>
      </li>
      <li @click.stop="openPolicy = !openPolicy" class="mobile-hidden">
       <button class="policy">
        <font-awesome-icon :icon="['fas', 'building-shield']" />
        <p>POLÍTICAS</p>
       </button>
      </li>
     </ul>
    </nav>
   </transition>

   <transition
    enter-active-class="animated fadeInRight"
    leave-active-class="animated fadeOutRight"
   >
    <nav v-click-outside="closePolicy" class="nav-policy" v-show="openPolicy">
     <ul>
      <li>
       <router-link to="/politica-de-privacidade">
        POLÍTICAS DE PRIVACIDADE
       </router-link>
      </li>
      <li>
       <router-link to="/politica-de-cookies">
        POLÍTICAS DE COOKIES
       </router-link>
      </li>
      <li>
       <router-link to="/politica-de-seguranca-da-informacao">
        POLÍTICAS DE SEGURANÇA DA INFORMAÇÃO
       </router-link>
      </li>
      <li>
       <router-link to="/codigo-etica">CÓDIGO DE ÉTICA</router-link>
      </li>
      <li>
       <router-link to="/politica-anticorrupcao">
        POLÍTICAS ANTICORRUPÇÃO
       </router-link>
      </li>
     </ul>
    </nav>
   </transition>
  </div>
 </header>
</template>
<script>
export default {
 name: "Header",
 data() {
  return {
   openMenu: true,
   openPolicy: false,
   isMobile: false,
  };
 },
 created() {
  if (window.innerWidth > 720) {
   this.isMobile = false;
  } else {
   this.isMobile = true;
  }

  if (this.isMobile) this.openMenu = false;
 },
 methods: {
  closePolicy() {
   this.openPolicy = false;
  },
  checkMobile() {
   if (this.isMobile) {
    this.openMenu = false;
   }
  },
  scrollToElement(el) {
   this.checkMobile();
   const distance = document.querySelector(el).offsetTop - 90;
   window.scroll({
    top: distance,
    left: 0,
    behavior: "smooth",
   });
  },
 },
};
</script>
<style scoped>
header {
 position: fixed;
 width: 100vw;
 height: 90px;
 background-color: #000;
 top: 0;
 left: 0;
 z-index: 999;
}

header .container {
 position: relative;
 height: 100%;
 display: flex;
 justify-content: space-between;
 align-items: center;
}

h1.logo {
 position: relative;
 width: 150px;
 height: 61px;
 background: url("../assets/images/rentacar.png") no-repeat;
 background-size: 100% 100%;
 text-indent: -9999px;
 overflow: hidden;
}

.icon-policy {
 position: relative;
 display: flex;
 justify-content: center;
 align-items: center;
 margin-left: 20px;
 cursor: pointer;
 width: 30px;
}

.icon-menu {
 position: relative;
 font-size: 2em;
 color: var(--primary);
}

.nav-policy {
 position: absolute;
 top: 90px;
 right: 0;
 width: fit-content;
}

.nav-policy ul {
 position: relative;
 width: fit-content;
 display: flex;
 flex-direction: column;
}

.nav-policy ul li {
 position: relative;
 width: 350px;
 background-color: var(--dark2);
 padding: 10px 15px;
}

.nav-policy ul li a {
 text-align: center;
}

nav ul {
 position: relative;
 width: fit-content;
 height: 44px;
 display: flex;
 justify-content: space-between;
 align-items: center;
}

/* nav ul li {
 position: relative;
 height: 100%;
} */

nav ul li.active::before,
nav ul li.active::after {
 position: absolute;
 content: "";
 width: 55px;
 height: 1px;
 top: 1px;
 right: 0px;
 background-color: var(--primary);
 transition: width 0.3s ease;
}

nav ul li.active::after {
 top: initial;
 right: initial;
 bottom: 0px;
 left: 0px;
}

nav ul li.active > a,
nav ul li:hover > a {
 color: var(--primary);
}

nav ul li a {
 color: #fff;
 height: 100%;
 display: flex;
 align-items: center;
 padding: 0 15px;
}

button.contract {
 display: flex;
 justify-content: center;
 align-items: center;
 padding: 10px 12px;
 background-color: var(--primary);
 cursor: pointer;
}

button.contract svg {
 font-size: 1.4em;
 margin-right: 15px;
 transition: color 0.2s ease;
}

button.contract p {
 font-size: 1.1em;
 transition: color 0.2s ease;
}

button.contract:hover > svg,
button.contract:hover > p {
 color: #fff;
}

button.policy {
 display: flex;
 justify-content: center;
 align-items: center;
 padding: 10px 12px;
 background-color: transparent;
 border: 1px solid #ff8b07;
 cursor: pointer;
}

button.policy p {
 font-size: 1.05em;
 transition: color 0.2s ease;
 color: #ff8b07;
}

button.policy svg {
 font-size: 1.1em;
 transition: color 0.2s ease;
 color: #ff8b07;
 margin-right: 8px;
}

@media only screen and (max-width: 720px) {
 nav {
  position: fixed;
  width: 100%;
  left: 0;
  top: 90px;
  min-height: calc(100vh - 90px);
  overflow-y: auto;
 }

 nav ul {
  width: 100%;
  display: block;
 }

 nav ul li {
  display: block;
  background-color: #000;
  height: 54px;
 }

 nav ul li a {
  font-size: 1.2em;
  padding-left: 35px;
 }

 nav ul li.active::after,
 nav ul li.active::before {
  width: 0;
  height: 0;
 }

 button.contract {
  width: 100%;
  left: 0;
  margin: 0 auto;
 }
}
</style>
