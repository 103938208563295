import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vClickOutside from "click-outside-vue3";
import { Money3Component } from "v-money3";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
 faEnvelope,
 faCalendar,
 faClock,
 faFileAlt,
} from "@fortawesome/free-regular-svg-icons";
import {
 faWhatsapp,
 faInstagram,
 faFacebookF,
} from "@fortawesome/free-brands-svg-icons";
import {
 faPhoneAlt,
 faEllipsisV,
 faCheck,
 faChevronLeft,
 faChevronRight,
 faMapMarkerAlt,
 faSortDown,
 faChevronUp,
 faPlus,
 faMinus,
 faBuildingShield,
 faTriangleExclamation
} from "@fortawesome/free-solid-svg-icons";

import "./assets/css/desktop.css";
import "./assets/css/mobile.css";
import "./assets/css/tablet.css";
import "./assets/css/animate.css";

const app = createApp(App);

library.add(
 faEnvelope,
 faPhoneAlt,
 faEllipsisV,
 faCheck,
 faChevronLeft,
 faChevronRight,
 faCalendar,
 faWhatsapp,
 faInstagram,
 faFacebookF,
 faMapMarkerAlt,
 faClock,
 faFileAlt,
 faSortDown,
 faChevronUp,
 faPlus,
 faMinus,
 faBuildingShield,
 faTriangleExclamation
);
app.component("font-awesome-icon", FontAwesomeIcon);

app.component("money", Money3Component);

const formatter = new Intl.NumberFormat("pt-BR", {
 style: "currency",
 currency: "BRL",
});
app.config.globalProperties.$filters = {
 currencyFilter(value) {
  return formatter.format(value);
 },
};

app.use(router);
app.use(vClickOutside);
app.mount("#app");
