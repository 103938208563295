<template>
  <div>
    <div class="input-item">
      <span>Profissão *</span>
      <input v-model="form.profession" type="text" />
    </div>
    <div class="input-item">
      <span>Cargo *</span>
      <input v-model="form.jobTitle" type="text" />
    </div>
    <div class="input-item">
      <span>Empresa *</span>
      <input v-model="form.company" type="text" />
    </div>
    <div class="input-item">
      <span>Telefone da empresa *</span>
      <input-mask
        v-model="form.workPhoneNumber"
        :mask="[{ mask: '(00) 0000-0000' }, { mask: '(00) 00000-0000' }]"
        placeholder="Ex: (19) 1234-5678"
      />
    </div>
    <div class="input-item">
      <span>Falar com *</span>
      <input type="text" v-model="form.workResponsible" placeholder />
    </div>
    <div class="input-item">
      <span>Tempo de empresa *</span>
      <input type="text" v-model="form.companyTime" placeholder />
    </div>
    <div class="input-item">
      <span>Renda Mensal *</span>
      <money v-model="form.income" />
    </div>
    <div class="input-item">
      <span>CEP da empresa *</span>
      <input-mask
        @blur="getCep(form.workZipCode)"
        v-model="form.workZipCode"
        :mask="[{ mask: '00000-000' }]"
      />
    </div>
    <transition enter-active-class="animated fadeInLeft">
      <div v-show="showFullAddress">
        <div class="input-item">
          <span>UF *</span>
          <input type="text" v-model="form.workUf" placeholder />
        </div>
        <div class="input-item">
          <span>Cidade *</span>

          <input type="text" v-model="form.workCity" placeholder />
        </div>
        <div class="input-item">
          <span>Bairro *</span>

          <input type="text" v-model="form.workNeighborhood" placeholder />
        </div>
        <div class="input-item">
          <span>Endereço *</span>

          <input type="text" v-model="form.workAddress" placeholder />
        </div>
        <div class="input-item">
          <span>Complemento</span>

          <input type="text" v-model="form.workComplement" placeholder />
        </div>
      </div>
    </transition>

    <transition enter-active-class="animated fadeIn">
      <p v-show="warningMessage != ''" class="warning">{{ warningMessage }}</p>
    </transition>
    <button @click="submitForm()" class="app-btn">
      <span>PROSSEGUIR</span>
    </button>
  </div>
</template>

<script>
import validators from "../util/validators";
import { IMaskComponent } from "vue-imask";
export default {
  name: "FormProfessionalInfo",
  components: {
    "input-mask": IMaskComponent,
  },
  data() {
    return {
      form: {
        profession: "",
        jobTitle: "",
        company: "",
        workPhoneNumber: "",
        workUf: "",
        workCity: "",
        workZipCode: "",
        workAddress: "",
        workNeighborhood: "",
        workComplement: "",
        workResponsible: "",
        companyTime: "",
        income: "",
      },
      showFullAddress: false,
      warningMessage: "",
    };
  },
  methods: {
    submitForm() {
      if (!this.form.profession) {
        this.warningMessage = "Por favor, informe a sua profissão";
        return;
      }
      if (!this.form.jobTitle) {
        this.warningMessage = "Por favor, informe o seu cargo";
        return;
      }
      if (!this.form.company) {
        this.warningMessage = "Por favor, informe o nome da empresa";
        return;
      }
      if (this.form.workPhoneNumber.length < 8) {
        this.warningMessage = "Por favor, informe um telefone válido";
        return;
      }
      if (!this.form.workResponsible) {
        this.warningMessage =
          "Por favor, informe o nome de um responsável da empresa";
        return;
      }
      if (!this.form.companyTime) {
        this.warningMessage = "Por favor, informe o seu tempo de empresa";
        return;
      }
      if (!this.form.income) {
        this.warningMessage = "Por favor, informe a sua renda mensal";
        return;
      }
      if (this.form.workZipCode.length != 8) {
        this.warningMessage = "Por favor, informe um CEP válido";
        return;
      }
      if (!this.form.workUf) {
        this.warningMessage = "Por favor, informe a UF da sua empresa";
        return;
      }
      if (!this.form.workCity) {
        this.warningMessage = "Por favor, informe a cidade da sua empresa";
        return;
      }
      if (!this.form.workNeighborhood) {
        this.warningMessage = "Por favor, informe o bairro da sua empresa";
        return;
      }
      if (!this.form.workAddress) {
        this.warningMessage = "Por favor, informe o endereço da sua empresa";
        return;
      }

      this.warningMessage = "";
      this.$emit("professionalInfoOk", this.form);
    },
    getCep(cep) {
      validators
        .validateCep(cep)
        .then((response) => {
          this.form.workUf = response.uf;
          this.form.workCity = response.city;
          this.form.workNeighborhood = response.neighborhood;
          this.form.workAddress = response.address;
        })
        .catch((err) => {
          console.log(err);
          this.warningMessage = err;
        })
        .finally(() => {
          this.showFullAddress = true;
        });
    },
  },
};
</script>
<style scoped>
.input-item {
  position: relative;
  width: 100%;
  margin: 25px 0 0 0;
}

.input-item span {
  font-family: fontRegular;
  position: relative;
  display: block;
  font-size: 1.3em;
  color: #555;
}

.input-item input,
.input-item select {
  font-family: fontRegular2;
  font-size: 1.4em;
  position: relative;
  width: 100%;
  height: 36px;
  border-bottom: 1px solid var(--primary);
}

.input-item input::placeholder {
  font-family: fontLight;
}

.warning {
  font-family: fontLight;
  position: relative;
  color: #f00;
  font-size: 1.4em;
  margin-top: 25px;
}
</style>
