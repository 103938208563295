import { createRouter, createWebHashHistory } from "vue-router";
import Home from "@/views/Home";
import CarroDetalhes from "@/views/CarroDetalhes";
import Faq from "@/views/Faq";
import Cadastro from "@/views/Cadastro";
import Terms from "@/views/TermosDeUso";

const routes = [
 {
  path: "/",
  name: "Home",
  component: Home,
  meta: {
   title: "Dahruj Rent a Car - Aluguel de carros em Campinas",
  },
 },
 {
  path: "/:slug/:version",
  name: "Detalhes",
  component: CarroDetalhes,
  meta: {
   title: "Carro-Detalhes",
  },
 },
 {
  path: "/perguntas-frequentes",
  name: "Faq",
  component: Faq,
  meta: {
   title: "FAQ",
  },
 },
 {
  path: "/cadastro",
  name: "Cadastro",
  component: Cadastro,
  meta: {
   title: "Cadastro",
  },
 },
 {
  path: "/ofertas",
  name: "Offers",
  component: () => import("@/views/Ofertas.vue"),
  meta: {
   title: "Ofertas",
  },
 },
 {
  path: "/politica-de-privacidade",
  name: "Terms",
  component: () => import("@/views/TermosDeUso.vue"),
  meta: {
   title: "Políticas de privacidade",
  },
 },
 {
  path: "/politica-de-cookies",
  name: "TermsCookies",
  component: () => import("@/views/TermosCookies.vue"),
  meta: {
   title: "Políticas de cookies",
  },
 },
 {
  path: "/politica-de-seguranca-da-informacao",
  name: "TermsSeguranca",
  component: () => import("@/views/TermosSeguranca.vue"),
  meta: {
   title: "Políticas de segurança da informação",
  },
 },
 {
  path: "/codigo-etica",
  name: "TermsEtica",
  component: () => import("@/views/TermosEtica.vue"),
  meta: {
   title: "Código de ética",
  },
 },
 {
  path: "/politica-anticorrupcao",
  name: "TermsAnticorrupcao",
  component: () => import("@/views/TermosAnticorrupcao.vue"),
  meta: {
   title: "Política Anticorrupção e regras de condutas gerais",
  },
 },
];

const router = createRouter({
 history: createWebHashHistory(),
 routes,
 scrollBehavior(to, from) {
  if (from.path != to.path) {
   return { top: 0 };
  }
 },
});

router.afterEach((to) => {
 if (to.name !== "Detalhes") {
  document.title =
   to?.meta?.title || "Dahruj Rent a Car - Aluguel de carros em Campinas";
 }
 if (process.env.NODE_ENV !== "development") {
  gtag("config", window.GA_TRACKING_ID, {
   page_path: to.fullPath,
   send_page_view: true,
  });
  fbq("track", "PageView");
 }
});

export default router;
