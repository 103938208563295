<template>
  <div>
    <div class="input-item">
      <span>Banco *</span>
      <input v-model="form.bank" type="text" />
    </div>
    <div class="input-item">
      <span>Agencia *</span>
      <input v-model="form.agency" type="text" />
    </div>
    <div class="input-item">
      <span>Conta *</span>
      <input v-model="form.account" type="text" />
    </div>
    <div class="input-item">
      <span>Gerente *</span>
      <input type="text" v-model="form.bankManager" />
    </div>
    <div class="input-item">
      <span>Telefone do gerente *</span>
      <the-mask
        v-model="form.bankPhoneNumber"
        :mask="['(##) ####-####', '(##) #####-####']"
        :masked="false"
        placeholder="Ex: (19) 1234-5678"
      />
    </div>
    <div class="input-item">
      <span>Cliente desde *</span>
      <the-mask
        v-model="form.clientSinceAux"
        placeholder="DD/MM/AAAA"
        :mask="['##/##/####']"
        :masked="true"
      />
    </div>

    <div class="input-item">
      <span>Indicação *</span>
      <div class="radios-holder">
        <div class="radio-item">
          <input
            type="radio"
            name="radioIndication"
            id="rad1"
            :value="true"
            v-model="form.isIndication"
          />
          <label for="rad1">Sim</label>
        </div>
        <div class="radio-item">
          <input
            type="radio"
            name="radioIndication"
            id="rad2"
            :value="false"
            v-model="form.isIndication"
          />
          <label for="rad2">Não</label>
        </div>
      </div>
    </div>

    <div class="input-item" v-show="form.isIndication">
      <span>Indicado por</span>
      <input type="text" v-model="form.indication" />
    </div>

    <transition enter-active-class="animated fadeIn">
      <p v-show="warningMessage != ''" class="warning">{{ warningMessage }}</p>
    </transition>
    <button @click="submitForm()" class="app-btn">
      <span>PROSSEGUIR</span>
    </button>
  </div>
</template>

<script>
import validators from "../util/validators";
export default {
  name: "FormBankInfo",
  data() {
    return {
      form: {
        bank: "",
        agency: "",
        account: "",
        clientSinceAux: "",
        bankManager: "",
        bankPhoneNumber: "",
        isIndication: false,
      },
      showFullAddress: false,
      warningMessage: "",
    };
  },
  methods: {
    submitForm() {
      if (!this.form.bank) {
        this.warningMessage = "Por favor, informe o seu banco";
        return;
      }
      if (!this.form.agency) {
        this.warningMessage = "Por favor, informe a sua agência bancária";
        return;
      }
      if (!this.form.account) {
        this.warningMessage = "Por favor, informe a sua conta bancária";
        return;
      }
      if (!this.form.bankManager) {
        this.warningMessage = "Por favor, informe o nome do seu gerente";
        return;
      }
      if (this.form.bankPhoneNumber.length < 6) {
        this.warningMessage = "Por favor, informe o telefone do seu gerente";
        return;
      }
      if (this.form.clientSinceAux.length != 10) {
        this.warningMessage =
          "Por favor, informe desde quando você é cliente do banco";
        return;
      }

      this.warningMessage = "";
      this.$emit("bankInfoOk", this.form);
    },
    getCep(cep) {
      validators
        .validateCep(cep)
        .then((response) => {
          this.form.workUf = response.uf;
          this.form.workCity = response.city;
          this.form.workNeighborhood = response.neighborhood;
          this.form.workAddress = response.address;
        })
        .catch((err) => {
          console.log(err);
          this.warningMessage = err;
        })
        .finally(() => {
          this.showFullAddress = true;
        });
    },
  },
};
</script>
<style scoped>
.input-item {
  position: relative;
  width: 100%;
  margin: 25px 0 0 0;
}

.input-item span {
  font-family: fontRegular;
  position: relative;
  display: block;
  font-size: 1.3em;
  color: #555;
}

.input-item input,
.input-item select {
  font-family: fontRegular2;
  font-size: 1.4em;
  position: relative;
  width: 100%;
  height: 36px;
  border-bottom: 1px solid var(--primary);
}

.input-item input::placeholder {
  font-family: fontLight;
}

.warning {
  font-family: fontLight;
  position: relative;
  color: #f00;
  font-size: 1.4em;
  margin-top: 25px;
}

.radios-holder {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.radio-item {
  position: relative;
  width: 50%;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.radio-item input {
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.radio-item label {
  font-size: 1.4em;
}
</style>
