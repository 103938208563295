<template>
  <footer>
    <div class="container">
      <div class="desktop4">
        <h1 class="logo">Rent a Car</h1>
        <div class="social-holder">
          <a
            href="https://www.instagram.com/dahrujrentacar/?hl=pt-br"
            target="_blank"
          >
            <font-awesome-icon :icon="['fab', 'instagram']" />
          </a>
          <a href="https://www.facebook.com/dahrujrentacar/" target="_blank">
            <font-awesome-icon :icon="['fab', 'facebook-f']" />
          </a>
        </div>
      </div>

      <div class="contact-holder desktop4">
        <div class="item-holder mr-2-desktop">
          <p class="title">Central de Reservas</p>
          <div class="item">
            <font-awesome-icon :icon="['fab', 'whatsapp']" />
            <a href="http://wa.me/551937561877" target="_blank"
              >(19) 3756-1877 (Campinas)</a
            >
          </div>
          <div class="item">
            <font-awesome-icon :icon="['fab', 'whatsapp']" />
            <a href="http://wa.me/551138323344" target="_blank"
              >(11) 3832-3344 (SP)</a
            >
          </div>
          <div class="item">
            <font-awesome-icon :icon="['fas', 'phone-alt']" />
            <a href="tel:1937561880">(19) 3756-1880</a>
          </div>
        </div>
        <div class="item-holder">
          <p class="title">Atendimento ao Condutor</p>
          <div class="item">
            <font-awesome-icon :icon="['fas', 'phone-alt']" />
            <a href="tel:08007754002">0800 775 4002</a>
          </div>
        </div>
        <div class="item-holder">
          <p class="title">E-mail</p>
          <div class="item">
            <font-awesome-icon :icon="['far', 'envelope']" />
            <a href="mailto:atendimento@dahrujrentacar.com.br"
              >atendimento@dahrujrentacar.com.br</a
            >
          </div>
        </div>
        <div class="item-holder">
          <p class="title">Termos</p>
          <div class="item">
            <font-awesome-icon :icon="['fas', 'building-shield']" />
            <router-link to="/politica-de-privacidade"
              >Políticas de privacidade
            </router-link>
          </div>
          <div class="item" style="margin-top: 8px">
            <font-awesome-icon :icon="['fas', 'building-shield']" />
            <router-link to="/politica-de-cookies"
              >Políticas de cookies</router-link
            >
          </div>
          <div class="item" style="margin-top: 8px">
            <font-awesome-icon :icon="['fas', 'building-shield']" />
            <router-link to="/politica-de-seguranca-da-informacao"
              >Políticas de segurança da informação
            </router-link>
          </div>
          <div class="item" style="margin-top: 8px">
            <font-awesome-icon :icon="['fas', 'building-shield']" />
            <router-link to="/codigo-etica">Código de ética</router-link>
          </div>
          <div class="item" style="margin-top: 8px">
            <font-awesome-icon :icon="['fas', 'building-shield']" />
            <router-link to="/politica-anticorrupcao"
              >Políticas anticorrupção
            </router-link>
          </div>
        </div>
      </div>
      <div class="contact-holder desktop4">
        <div class="item-holder">
          <p class="title">Horário de Funcionamento</p>
          <div class="item">
            <font-awesome-icon :icon="['far', 'clock']" />
            <a
              >Campinas: Segunda a sexta das 8h às 18h, sábado das 9h às 13h.</a
            >
          </div>
          <div class="item">
            <font-awesome-icon :icon="['far', 'clock']" />
            <a>São Paulo: Segunda a sexta das 9h às 19h.</a>
          </div>
        </div>
        <div class="item-holder">
          <p class="title">Endereço</p>
          <div class="item">
            <font-awesome-icon :icon="['fas', 'map-marker-alt']" />
            <a>
              Avenida José de Souza Campos, 2256, Chácara da Barra | Campinas/SP
              | 13.090-615
            </a>
          </div>
          <div class="item" style="margin-top: 8px">
            <font-awesome-icon :icon="['fas', 'map-marker-alt']" />
            <a>
              Avenida Dr. Gastão Vidigal, 2351, Vila Leopoldina | São Paulo |
              05.314-001
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-holder">
      <p>
        {{
          `Dahruj Locação de Veículos LTDA - 34.225.917/0001-06 | Todos os direitos reservados | ${new Date().getFullYear()}`
        }}
      </p>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
};
</script>
<style scoped>
footer {
  position: relative;
  width: 100vw;
  min-height: 300px;
  padding: 30px 0 100px 0;
  background-color: #000;
}

footer .container {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.row p {
  color: #fff;
}

h1.logo {
  position: relative;
  width: 150px;
  height: 61px;
  background: url("../assets/images/rentacar.png") no-repeat;
  background-size: 100% 100%;
  text-indent: -9999px;
  overflow: hidden;
  margin-top: 25px;
}

.social-holder {
  position: relative;
  margin-top: 35px;
  width: 150px;
  display: flex;
  justify-content: center;
}

.social-holder svg {
  position: relative;
  margin: 0 15px;
  font-size: 1.7em;
  cursor: pointer;
}

.contact-holder {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.contact-holder .item-holder {
  position: relative;
  margin: 12px 0 12px 0;
}

.contact-holder .item-holder .title {
  font-size: 1.2em;
  color: var(--primary);
  margin-bottom: 5px;
}

.contact-holder .item {
  position: relative;
  width: 100%;
}

svg {
  color: var(--primary);
  margin-right: 10px;
  font-size: 1.4em;
}

.contact-holder .item a {
  font-family: fontLight;
  color: #fff;
  font-size: 1.1em;
  line-height: 1.6em;
}

.bottom-holder {
  position: absolute;
  width: 100vw;
  padding: 25px 0;
  border-top: 1px solid var(--primary);
  bottom: 0;
}

.bottom-holder p {
  font-family: fontLight;
  text-align: center;
  color: #fff;
  font-weight: lighter;
}

@media only screen and (max-width: 720px) {
  footer .container {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contact-holder {
    margin-top: 30px;
    width: 270px;
  }

  .contact-holder .item a {
    font-size: 1.2em;
  }
}
</style>
