<template>
  <div>
    <div class="input-item">
      <span>Nome Completo *</span>
      <input v-model="form.fullName" type="text" />
    </div>
    <div class="input-item">
      <span>E-mail *</span>
      <input
        type="email"
        v-model="form.email"
        placeholder="exemplo@dominio.com"
      />
    </div>
    <div class="input-item">
      <span>Senha *</span>
      <input type="password" v-model="form.password" placeholder="--------" />
    </div>
    <div class="input-item">
      <span>Confirmar senha *</span>
      <input type="password" v-model="form.password2" placeholder="--------" />
    </div>
    <div class="input-item">
      <span>Telefone *</span>
      <input-mask
        v-model="form.phoneNumber"
        :mask="[{ mask: '(00) 0000-0000' }, { mask: '(00) 00000-0000' }]"
        placeholder="Ex: (19) 1234-5678"
      />
    </div>
    <div class="input-item">
      <span>Celular *</span>
      <input-mask
        v-model="form.mobileNumber"
        :mask="[{ mask: '(00) 0000-0000' }, { mask: '(00) 00000-0000' }]"
        placeholder="Ex: (19) 12345-6789"
      />
    </div>
    <div class="input-item">
      <span>Data de Nascimento *</span>
      <input-mask
        v-model="form.birthdayAux"
        placeholder="DD/MM/AAAA"
        :mask="[{ mask: '00/00/0000' }]"
      />
    </div>
    <div class="input-item">
      <span>CPF *</span>
      <input-mask
        v-model="form.cpf"
        :mask="[{ mask: '000.000.000-00' }, { mask: '00.000.000/0000-00' }]"
      />
    </div>
    <div class="input-item">
      <span>RG *</span>
      <input v-model="form.rg" type="text" />
    </div>
    <div class="input-item">
      <span>CNH *</span>
      <input v-model="form.cnh" type="text" />
    </div>
    <div class="input-item">
      <span>CNH Validade *</span>
      <input-mask
        v-model="form.cnhexpirationDateAux"
        placeholder="DD/MM/AAAA"
        :mask="[{ mask: '00/00/0000' }]"
      />
    </div>
    <div class="input-item">
      <span>Nacionalidade *</span>
      <input v-model="form.nationality" type="text" />
    </div>
    <div class="input-item">
      <span>Naturalidade *</span>
      <input v-model="form.birthPlace" type="text" />
    </div>
    <div class="input-item">
      <span>Estado Civil *</span>
      <select v-model="form.maritalStatus">
        <option value="">Selecione uma opção</option>
        <option value="Solteiro(a)">Solteiro(a)</option>
        <option value="Casado(a)">Casado(a)</option>
        <option value="Divorciado(a)">Divorciado(a)</option>
        <option value="Viúvo(a)">Viúvo(a)</option>
        <option value="Separado(a)">Separado(a)</option>
      </select>
    </div>
    <div class="input-item">
      <span>CEP *</span>
      <input-mask
        @blur="getCep(form.zipcode)"
        v-model="form.zipcode"
        :mask="[{ mask: '00000-000' }]"
      />
    </div>
    <transition enter-active-class="animated fadeInLeft">
      <div v-show="showFullAddress">
        <div class="input-item">
          <span>UF *</span>
          <input type="text" v-model="form.uf" placeholder />
        </div>
        <div class="input-item">
          <span>Cidade *</span>

          <input type="text" v-model="form.city" placeholder />
        </div>
        <div class="input-item">
          <span>Bairro *</span>

          <input type="text" v-model="form.neighborhood" placeholder />
        </div>
        <div class="input-item">
          <span>Endereço *</span>

          <input type="text" v-model="form.address" placeholder />
        </div>
        <div class="input-item">
          <span>Complemento</span>

          <input type="text" v-model="form.complement" placeholder />
        </div>
      </div>
    </transition>
    <div class="input-item">
      <span>Propriedade *</span>
      <select v-model="form.ownProperty">
        <option value="">Selecione uma opção</option>
        <option :value="true">Própria</option>
        <option :value="false">Alugada</option>
      </select>
    </div>
    <transition enter-active-class="animated fadeIn">
      <p v-show="warningMessage != ''" class="warning">{{ warningMessage }}</p>
    </transition>
    <button @click="submitForm()" class="app-btn">
      <span>PROSSEGUIR</span>
    </button>
  </div>
</template>

<script>
import validators from "../util/validators";
import { IMaskComponent } from "vue-imask";
export default {
  name: "FormPersonalInfo",
  components: {
    "input-mask": IMaskComponent,
  },
  data() {
    return {
      form: {
        fullName: "",
        birthdayAux: "",
        nationality: "",
        birthPlace: "",
        maritalStatus: "",
        email: "",
        mobileNumber: "",
        phoneNumber: "",
        cpf: "",
        rg: "",
        cnh: "",
        cnhexpirationDateAux: "",
        ownProperty: null,
        uf: "",
        city: "",
        zipcode: "",
        address: "",
        neighborhood: "",
        complement: "",
        password: "",
        password2: "",
      },
      showFullAddress: false,
      warningMessage: "",
    };
  },
  methods: {
    submitForm() {
      if (!validators.validateFullName(this.form.fullName)) {
        this.warningMessage = "Por favor, informe o seu nome completo";
        return;
      }
      if (!validators.validateEmail(this.form.email)) {
        this.warningMessage = "Por favor, informe um e-mail válido";
        return;
      }
      if (!this.form.password) {
        this.warningMessage = "Por favor, informe uma senha";
        return;
      }
      if (this.form.password !== this.form.password2) {
        this.warningMessage =
          "Por favor, verifique sua senha e confirmação de senha";
        return;
      }
      if (this.form.phoneNumber.length < 8) {
        this.warningMessage = "Por favor, informe um telefone válido";
        return;
      }
      if (this.form.mobileNumber.length < 8) {
        this.warningMessage = "Por favor, informe um celular válido";
        return;
      }
      if (this.form.birthdayAux.length != 10) {
        this.warningMessage =
          "Por favor, informe uma data de nascimento válida";
        return;
      }
      if (!validators.validateCPF(this.form.cpf)) {
        this.warningMessage = "Por favor, informe um CPF válido";
        return;
      }
      if (this.form.rg.length < 6) {
        this.warningMessage = "Por favor, informe um RG válido";
        return;
      }
      if (this.form.cnh.length < 6) {
        this.warningMessage = "Por favor, informe sua CNH corretamente";
        return;
      }
      if (this.form.cnhexpirationDateAux.length < 10) {
        this.warningMessage = "Por favor, informe uma validade de CNH correta";
        return;
      }
      if (!this.form.nationality) {
        this.warningMessage = "Por favor, informe sua nacionalidade";
        return;
      }
      if (!this.form.birthPlace) {
        this.warningMessage = "Por favor, informe sua naturalidade";
        return;
      }
      if (!this.form.maritalStatus) {
        this.warningMessage = "Por favor, informe seu estado civil";
        return;
      }
      if (this.form.zipcode.length != 8) {
        this.warningMessage = "Por favor, informe um CEP válido";
        return;
      }
      if (!this.form.uf) {
        this.warningMessage = "Por favor, informe seu UF";
        return;
      }
      if (!this.form.city) {
        this.warningMessage = "Por favor, informe sua cidade";
        return;
      }
      if (!this.form.neighborhood) {
        this.warningMessage = "Por favor, informe o seu bairro";
        return;
      }
      if (!this.form.address) {
        this.warningMessage = "Por favor, informe o seu endereço";
        return;
      }
      if (this.form.ownProperty === null) {
        this.warningMessage =
          "Por favor, informe se sua propriedade é própria ou alugada";
        return;
      }

      this.warningMessage = "";
      this.$emit("personalInfoOk", this.form);
    },
    getCep(cep) {
      validators
        .validateCep(cep)
        .then((response) => {
          this.form.uf = response.uf;
          this.form.city = response.city;
          this.form.neighborhood = response.neighborhood;
          this.form.address = response.address;
        })
        .catch((err) => {
          console.log(err);
          this.warningMessage = err;
        })
        .finally(() => {
          this.showFullAddress = true;
        });
    },
  },
};
</script>
<style scoped>
.input-item {
  position: relative;
  width: 100%;
  margin: 25px 0 0 0;
}

.input-item span {
  font-family: fontRegular;
  position: relative;
  display: block;
  font-size: 1.3em;
  color: #555;
}

.input-item input,
.input-item select {
  font-family: fontRegular2;
  font-size: 1.4em;
  position: relative;
  width: 100%;
  height: 36px;
  border-bottom: 1px solid var(--primary);
}

.input-item input::placeholder {
  font-family: fontLight;
}

.warning {
  font-family: fontLight;
  position: relative;
  color: #f00;
  font-size: 1.4em;
  margin-top: 25px;
}
</style>
