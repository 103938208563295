<template>
  <div>
    <section class="intro">
      <div class="container">
        <font-awesome-icon
          @click="back()"
          :icon="['fas', 'chevron-left']"
          class="icon-menu"
        />
        <img
          class="tablet-hidden mobile-hidden"
          src="../assets/images/rentacar.png"
        />
        <h1 class="desktop-hidden">CADASTRO</h1>
      </div>
    </section>
    <section class="content">
      <div class="content-inner">
        <transition enter-active-class="animated fadeIn">
          <div v-show="step == 0" class="container small">
            <RegisterInfo></RegisterInfo>
            <button @click="step = 1" class="app-btn">
              <span>PROSSEGUIR</span>
            </button>
          </div>
        </transition>
        <transition enter-active-class="animated fadeIn">
          <div v-show="step > 0" class="container small">
            <div class="progress-holder">
              <div
                class="dots-holder"
                :class="{
                  one: step == 2,
                  two: step == 3,
                  three: step == 4 || step == 5,
                }"
              >
                <span :class="{ active: step > 0 }"></span>
                <span :class="{ active: step > 1 }"></span>
                <span :class="{ active: step > 2 }"></span>
                <span :class="{ active: step > 3 }"></span>
              </div>
              <p>{{ stepName }}</p>
            </div>
          </div>
        </transition>

        <transition enter-active-class="animated fadeIn">
          <div v-show="step == 1" class="container small">
            <FormPersonalInfo
              @personalInfoOk="step1Concluded"
            ></FormPersonalInfo>
          </div>
        </transition>

        <transition enter-active-class="animated fadeIn">
          <div v-show="step == 2" class="container small">
            <FormProfessionalInfo
              @professionalInfoOk="step2Concluded"
            ></FormProfessionalInfo>
          </div>
        </transition>

        <transition enter-active-class="animated fadeIn">
          <div v-show="step == 3" class="container small">
            <FormBankInfo @bankInfoOk="step3Concluded"></FormBankInfo>
            <button @click="sendUser()" class="app-btn">
              <span v-show="!loading">CADASTRAR</span>
              <div v-show="loading" class="loading2 black"></div>
            </button>
          </div>
        </transition>

        <!-- Removido (vue-upload-multiple-image) por falta de compatibilidade ao Vue3 -->

        <!-- <transition enter-active-class="animated fadeIn">
          <div v-show="step == 4" class="container small">
            <p class="file-title">CNH *</p>
            <vue-upload-multiple-image
              key="cnhUpload"
              idUpload="cnh"
              class="uploader mt-1"
              dragText="Arraste e solte aqui"
              browseText="Ou clique para fazer upload"
              primaryText="Imagem"
              markIsPrimaryText="CNH"
              popupText="Cópia da CNH"
              :maxImage="1"
              dropText="Solte a imagem"
              :data-images="cnhImage"
              @upload-success="uploadCnhSuccess"
              @edit-image="editCnh"
            ></vue-upload-multiple-image>

            <p class="file-title mt-2">Comprovante de endereço *</p>
            <vue-upload-multiple-image
              key="addressUpload"
              idUpload="address"
              class="uploader mt-1"
              dragText="Arraste e solte aqui"
              browseText="Ou clique para fazer upload"
              primaryText="Imagem"
              markIsPrimaryText="Comprovante de endereço"
              popupText="Comprovante de endereço"
              :maxImage="1"
              dropText="Solte a imagem"
              :data-images="addressImage"
              @upload-success="uploadAddressSuccess"
              @edit-image="editAddress"
            ></vue-upload-multiple-image>

            <p class="file-title mt-2">
              Comprovante de renda, extrato bancário ou imposto de renda *
            </p>
            <vue-upload-multiple-image
              key="incomeUpload"
              idUpload="income"
              class="uploader mt-1"
              dragText="Arraste e solte aqui"
              browseText="Ou clique para fazer upload"
              primaryText="Imagem"
              markIsPrimaryText="Comprovante de renda"
              popupText="Comprovante de renda"
              :maxImage="1"
              dropText="Solte a imagem"
              :data-images="incomeImage"
              @upload-success="uploadIncomeSuccess"
              @edit-image="editIncome"
            ></vue-upload-multiple-image>

            <transition enter-active-class="animated fadeIn mt-1">
              <p v-show="warningMessage != ''" class="warning">
                {{ warningMessage }}
              </p>
            </transition>
            <button @click="sendUser()" class="app-btn">
              <span v-show="!loading">CADASTRAR</span>
              <div v-show="loading" class="loading2 black"></div>
            </button>
          </div>
        </transition> -->

        <transition enter-active-class="animated fadeIn">
          <div v-show="step == 4" class="container small">
            <h1 class="register">Cadastro Completo</h1>
            <p class="mt-1 intro">
              Obrigado por agilizar seu processo de aprovação.<br />Em breve
              entraremos em contato.
            </p>

            <a href="#/" class="btn">VOLTAR AO SITE</a>
          </div>
        </transition>
      </div>
    </section>
  </div>
</template>

<script>
import http from "@/http";
import RegisterInfo from "../components/RegisterInfo";
import FormPersonalInfo from "../components/FormPersonalInfo";
import FormProfessionalInfo from "../components/FormProfessionalInfo";
import FormBankInfo from "../components/FormBankInfo";
// import VueUploadMultipleImage from "vue-upload-multiple-image"; <!-- Removido por falta de compatibilidade ao Vue3 -->
import validators from "../util/validators";

export default {
  name: "Cadastro",
  components: {
    RegisterInfo,
    FormPersonalInfo,
    FormProfessionalInfo,
    FormBankInfo,
    // VueUploadMultipleImage,
  },
  data() {
    return {
      step: 0,
      user: Object,
      formData: new FormData(),
      cnhFormData: null,
      addressFormData: null,
      incomeFormData: null,
      cnhImage: [],
      addressImage: [],
      incomeImage: [],
      warningMessage: "",
      loading: false,
    };
  },
  computed: {
    stepName() {
      if (this.step == 1) return "Dados Pessoais";
      if (this.step == 2) return "Dados profissionais";
      if (this.step == 3) return "Dados bancários";
      // if (this.step == 4) return "Anexo de documentos"; <!-- Removido (vue-upload-multiple-image) por falta de compatibilidade ao Vue3 -->
      if (this.step == 4) return "Finalizado";
      return "";
    },
  },
  methods: {
    sendUser() {
      if (!this.formData.get("CNHImage")) {
        this.warningMessage = "Por favor, faça upload da sua CNH";
        return;
      }

      if (!this.formData.get("ProofAddress")) {
        this.warningMessage =
          "Por favor, faça upload de um comprovante de endereço";
        return;
      }

      if (!this.formData.get("ProofIncome")) {
        this.warningMessage =
          "Por favor, faça upload de um comprovante de renda";
        return;
      }
      this.warningMessage = "";
      this.loading = true;
      this.user.birthday = validators.formatDate(this.user.birthdayAux);
      this.user.cnhexpirationDate = validators.formatDate(
        this.user.cnhexpirationDateAux
      );
      this.user.clientSince = validators.formatDate(this.user.clientSinceAux);

      http
        .post("user", this.user)
        .then((response) => {
          const token = response.data.accessToken;
          this.sendDocuments(token);
        })
        .catch((err) => {
          console.log(err);
          this.warningMessage = err.response.data;
          this.loading = false;
        });
    },
    sendDocuments(token) {
      http
        .post("User/Documents", this.formData, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // redirect
          this.step = 4;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    step3Concluded(value) {
      this.user = { ...this.user, ...value };
      this.scrollToTop();
      setTimeout(() => {
        this.step = 4;
      }, 600);
    },
    step2Concluded(value) {
      this.user = { ...this.user, ...value };
      this.scrollToTop();
      setTimeout(() => {
        this.step = 3;
      }, 600);
    },
    step1Concluded(value) {
      this.user = value;
      this.scrollToTop();
      setTimeout(() => {
        this.step = 2;
      }, 600);
    },
    uploadCnhSuccess(formData) {
      this.formData.append("CNHImage", formData.get("file"));
    },
    editCnh(formData) {
      this.formData.delete("CNHImage");
      this.formData.append("CNHImage", formData.get("file"));
    },
    uploadAddressSuccess(formData) {
      this.formData.append("ProofAddress", formData.get("file"));
    },
    editAddress(formData) {
      this.formData.delete("ProofAddress");
      this.formData.append("ProofAddress", formData.get("file"));
    },
    uploadIncomeSuccess(formData) {
      this.formData.append("ProofIncome", formData.get("file"));
    },
    editIncome(formData) {
      this.formData.delete("ProofIncome");
      this.formData.append("ProofIncome", formData.get("file"));
    },
    back() {
      if (this.step < 1) {
        window.history.back();
      } else {
        this.step -= 1;
      }
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style scoped>
section.intro {
  width: 100vw;
  height: 100px;
  background-color: var(--dark2);
}

section.intro .container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

section.intro .container h1 {
  color: var(--primary);
  font-size: 2em;
  letter-spacing: 2px;
}

.icon-menu {
  position: absolute;
  left: 0;
  font-size: 2em;
  color: var(--primary);
  cursor: pointer;
}

section.content {
  width: 100vw;
  padding: 0 !important;
  background-color: var(--dark2);
  min-height: 400px;
}

section.content .content-inner {
  width: 100vw;
  padding: 35px 0;
  border-radius: 35px 35px 0 0;
  min-height: 400px;
  background-color: #fff;
}

.container.small {
  max-width: 540px;
}

.progress-holder {
  position: relative;
  width: 100%;
  padding: 18px 0;
  margin-bottom: 35px;
}

.progress-holder p {
  position: relative;
  font-family: fontMedium;
  font-size: 1.4em;
  text-align: center;
  margin-top: 20px;
}

.dots-holder {
  position: absolute;
  width: 100%;
  height: 100%;
  border-top: 1px dashed #ccc;
  top: 0px;
  display: flex;
  justify-content: space-between;
}

.dots-holder span {
  position: relative;
  width: 20px;
  height: 20px;
  background-color: #ccc;
  border-radius: 100%;
  top: -11px;
  transition: background-color 0.3s ease;
}

.dots-holder span.active {
  background-color: var(--primary);
}

.dots-holder::before {
  position: absolute;
  content: "";
  width: 0;
  height: 2px;
  top: -2px;
  left: 0;
  background-color: var(--primary);
  transition: width 0.3s ease;
}

.dots-holder.one::before {
  width: 33.3333%;
}
.dots-holder.two::before {
  width: 66.6666%;
}
.dots-holder.three::before {
  width: 100%;
}

.file-title {
  font-family: fontRegular;
  position: relative;
  font-size: 1.3em;
  color: #555;
}

.warning {
  font-family: fontLight;
  position: relative;
  color: #f00;
  font-size: 1.4em;
  margin-top: 25px;
}

h1.register {
  font-size: 2em;
  text-align: center;
  color: var(--primary);
  margin-top: 25px;
}

p.intro {
  font-size: 1.4em;
  text-align: center;
}

a.btn {
  position: relative;
  display: block;
  font-size: 1.3em;
  max-width: 300px;
  text-align: center;
  margin: 25px auto 0 auto;
  padding: 16px 28px;
  background-color: var(--primary);
  border-radius: 5px;
}
</style>

<style>
.uploader,
.uploader .image-container {
  width: 100% !important;
}
</style>
