<template>
  <div>
    <Header></Header>
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div v-show="loadingFull" class="full-loading">
        <div class="loading2"></div>
      </div>
    </transition>

    <section class="car">
      <div class="container">
        <div
          class="versions-holder"
          :class="{ 'no-opacity': car.version.length < 2 }"
        >
          <font-awesome-icon
            @click="changeVersion('prev')"
            v-show="selectedVersionPos != 0"
            :icon="['fas', 'chevron-left']"
          />
          <p>MODELO</p>
          <font-awesome-icon
            @click="changeVersion('next')"
            v-show="selectedVersionPos != 1"
            :icon="['fas', 'chevron-right']"
          />
        </div>
        <h1 class="car-name desktop12 tablet12 mobile12">
          {{ selectedVersionName }}
        </h1>
        <div class="car-holder">
          <img :src="selectedColor.path" alt="carro" />
        </div>
        <div class="colors-holder">
          <div
            @click="selectedColor = color"
            v-for="color in car.carImage"
            :key="color.id"
            class="color"
            :class="{ active: color.id == selectedColor.id }"
            :style="`background-color:${color.colorPrimary}`"
          ></div>
        </div>
      </div>
    </section>
    <section class="info">
      <div class="container">
        <div class="col1">
          <ul>
            <li
              v-for="(feature, index) in selectedVersionArrFeatures"
              :key="index"
            >
              {{ feature }}
            </li>
          </ul>
        </div>
        <transition enter-active-class="animated fadeIn">
          <div class="col2" v-show="spotlightFeatures.length > 0">
            <div
              id="swiperContainer"
              class="slider-holder swiper-container"
              style="overflow: hidden"
            >
              <div class="swiper-wrapper">
                <article
                  v-for="feature in spotlightFeatures"
                  :key="feature.id"
                  class="swiper-slide"
                >
                  <h1>{{ feature.title }}</h1>
                  <img :src="feature.image" alt="feature" />
                  <p>
                    {{ feature.description }}
                  </p>
                </article>
              </div>

              <div
                v-show="spotlightFeatures.length > 1"
                class="swiper-pagination"
              ></div>
              <div
                v-show="spotlightFeatures.length > 1"
                class="swiper-button-next banner-control-next"
              ></div>
              <div
                v-show="spotlightFeatures.length > 1"
                class="swiper-button-prev banner-control-prev"
              ></div>
            </div>
          </div>
        </transition>
      </div>
    </section>

    <section v-show="plans.length > 0" class="plan">
      <div class="container">
        <p class="section-title white-line text-black black-arrows">
          ESCOLHA<br />O SEU PLANO
        </p>
        <div class="plans-holder">
          <div
            @click="selectPlan(plan)"
            v-for="plan in plans"
            :key="plan.id"
            class="plan-card"
            :class="{ active: selectedPlan.value == plan.value }"
          >
            <img src="../assets/images/icon-calendar.png" class="icon" />
            <p class="number">{{ plan.value }}</p>
            <p class="label">MESES</p>
          </div>
        </div>
      </div>
    </section>

    <section v-show="this.selectedPlan" class="km">
      <div class="container">
        <p class="section-title black-line">ESCOLHA SEU<br />PACOTE DE KM</p>
        <div class="km-holder mt-2">
          <div
            @click="selectKm(km.km)"
            v-for="km in kmArray"
            :key="km.id"
            class="km-item"
            :class="{ active: selectedKm == km.km }"
          >
            <p>{{ km.km }}</p>
            <span>km/mês</span>
          </div>
        </div>
      </div>
    </section>

    <transition enter-active-class="animated fadeIn">
      <section v-show="price">
        <div class="container">
          <div v-show="!formSent">
            <p class="contact-call">
              {{ `Plano de ${selectedPlan.title}, ${selectedKm}km/mês:` }}
            </p>
            <div class="total mt-1">Total</div>
            <div v-if="price > 0" class="price">
              {{ $filters.currencyFilter(price) }}<span> / mês</span>
            </div>
            <div v-else class="price">{{ price }}</div>

            <p class="contact-call">
              Preencha os campos abaixo que nós entraremos em contato o mais
              rápido o possível.
            </p>

            <div class="form-holder">
              <div class="value-holder mt-1">
                <span class="input-label">Nome *</span>
                <input type="text" v-model="objForm.name" />
              </div>

              <div class="value-holder mt-1">
                <span class="input-label">E-mail *</span>
                <input type="text" v-model="objForm.email" />
              </div>

              <div class="value-holder mt-1">
                <span class="input-label">Telefone *</span>
                <input-mask
                  v-model="objForm.phoneNumber"
                  :mask="[
                    { mask: '(00) 0000-0000' },
                    { mask: '(00) 00000-0000' },
                  ]"
                />
              </div>
              <transition enter-active-class="animated fadeIn">
                <p class="warning" v-show="warningMessage != ''">
                  {{ warningMessage }}
                </p>
              </transition>

              <button @click="sendForm()" class="btn">
                <span v-show="!loading">TENHO INTERESSE</span>
                <div v-show="loading" class="loading2"></div>
              </button>
            </div>
          </div>
          <div v-show="formSent" class="form-holder sent">
            <h1>Obrigado pelo interesse!</h1>
            <p>
              Em breve um dos nossos consultores entrará em contato com você.
            </p>
          </div>
        </div>
      </section>
    </transition>

    <Footer></Footer>
  </div>
</template>
<script>
import http from "@/http";
import Header from "../components/Header";
import Footer from "../components/Footer";
import validators from "../util/validators";
import { IMaskComponent } from "vue-imask";
import "swiper/css";
import "swiper/css/bundle";
import Swiper, { Navigation, Pagination } from "swiper";
Swiper.use([Navigation, Pagination]);

export default {
  name: "CarroDetalhes",
  components: {
    Header,
    Footer,
    "input-mask": IMaskComponent,
  },
  data() {
    return {
      car: {
        version: [],
      },
      selectedColor: "",
      selectedKm: "",
      selectedPlan: "",
      selectedVersion: "",
      selectedVersionPos: 0,
      plans: [],
      kmArray: [],
      spotlightFeatures: [],
      objForm: {
        name: "",
        email: "",
        phoneNumber: "",
      },
      warningMessage: "",
      loading: false,
      loadingFull: true,
      formSent: false,
    };
  },
  created() {
    http
      .get(`car?id=${this.$route.params.slug}`)
      .then((response) => {
        localStorage.x = JSON.stringify(response);
        this.car = response.data;
        document.title = `Dahruj Rent a Car - ${this.car.model}`;
        this.selectedColor = this.car.carImage[0] || { path: this.car.image };
        this.selectedVersion = this.car.version.filter(
          (el) => el.name === this.$route.params.version
        )[0];
        this.selectedVersion.arrFeatures =
          this.selectedVersion.features.split(",");
        this.car.version.map((el, index) => {
          if (el.id === this.selectedVersion.id) {
            this.selectedVersionPos = index;
          }
        });
        this.loadSpotlightFeatures(this.selectedVersion);
        this.loadPlans(this.selectedVersion);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.loadingFull = false;
      });
  },
  computed: {
    selectedVersionArrFeatures() {
      return this.selectedVersion && this.selectedVersion.arrFeatures
        ? this.selectedVersion.arrFeatures
        : [];
    },
    selectedVersionName() {
      return this.selectedVersion && this.selectedVersion.name
        ? this.selectedVersion.name
        : "";
    },
    price() {
      let result = {};
      localStorage.x = JSON.stringify(this.selectedVersion);
      // console.log(this.selectedKm, this.selectedPlan);
      if (this.selectedKm && this.selectedPlan) {
        result = this.selectedVersion.pricing.filter(
          (item) =>
            item.km === this.selectedKm && item.plan.id === this.selectedPlan.id
        )[0];
        if (result.price <= 0) {
          result.price = "Preço sob Consulta";
        }
      } else {
        result.price = null;
      }
      return result.price;
    },
  },
  methods: {
    sendForm() {
      if (this.objForm.name == "") {
        this.warningMessage = "Por favor, informe o seu nome.";
        return;
      }
      if (!validators.validateEmail(this.objForm.email)) {
        this.warningMessage = "Por favor, informe um e-mail válido.";
        return;
      }
      if (!this.objForm.phoneNumber) {
        this.warningMessage = "Por favor, informe um e-mail válido.";
        return;
      }

      this.loading = true;
      this.warningMessage = "";

      const form = {
        name: this.objForm.name,
        email: this.objForm.email,
        phoneNumber: this.objForm.phoneNumber,
        selectedCar: this.$route.params.version,
        selectedPlan: this.selectedPlan.title,
        selectedKm: `${this.selectedKm} km/mês`,
        viewedPrice: this.price,
        message: "--",
      };

      http
        .post("contact", form)
        .then((response) => {
          this.formSent = true;
        })
        .catch((err) => {
          console.log(err);
          this.warningMessage =
            "Algo deu errado. Por favor, tente novamente em alguns instantes.";
        })
        .finally(() => {
          this.loading = false;
        });

      // http
      //   .post("contact", this.objForm)
      //   .then(response => {
      //     if (response.status === 200) {
      //       this.formSent = true;
      //       this.objForm = {
      //         selectedCar: "",
      //         name: "",
      //         email: "",
      //         phoneNumber: "",
      //         message: ""
      //       };
      //     }
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
    },
    selectKm(val) {
      if (this.formSent) return;

      this.selectedKm = val != this.selectedKm ? val : "";
    },
    selectPlan(plan) {
      if (this.formSent) return;

      this.selectedPlan = plan.id != this.selectedPlan.id ? plan : "";
      this.loadKmArray();
    },
    loadPlans(version) {
      const localPlans = [];
      this.plans = [];
      version.pricing.forEach((el) => {
        localPlans.push(el.plan);
      });
      const map = new Map();
      for (const item of localPlans) {
        if (!map.has(item.id)) {
          map.set(item.id, false);
          this.plans.push({
            id: item.id,
            title: item.title,
          });
        }
      }
      this.plans.map((item) => {
        item.value = Number(item.title.split(" ")[0]);
      });
      this.plans.sort((a, b) => (a.value > b.value ? 1 : -1));
      this.loadKmArray();
    },
    loadKmArray() {
      this.kmArray = this.selectedVersion.pricing.filter(
        (item) => item.plan.id === this.selectedPlan.id
      );
    },
    loadSpotlightFeatures(version) {
      this.spotlightFeatures = version.feature;
      setTimeout(() => {
        this.slider = new Swiper("#swiperContainer", {
          slidesPerView: 1,
          spaceBetween: 0,
          autoHeight: true,
          autoplay: false,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
      }, 500);
    },
    changeVersion(val) {
      this.selectedPlan = "";
      this.selectedKm = "";
      if (val == "prev") {
        if (this.selectedVersionPos > 0) {
          this.selectedVersionPos--;
          this.selectedVersion = this.car.version[this.selectedVersionPos];
        }
      } else if (val == "next") {
        if (this.selectedVersionPos < this.car.version.length - 1) {
          this.selectedVersionPos++;
          this.selectedVersion = this.car.version[this.selectedVersionPos];
        }
      }
      this.selectedVersion.arrFeatures =
        this.selectedVersion.features.split(",");
      this.loadPlans(this.selectedVersion);
      this.loadSpotlightFeatures(this.selectedVersion);
    },
  },
};
</script>
<style scoped>
.swiper-button-next,
.swiper-button-prev {
  top: 26px;
  width: 28px;
  height: 28px;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 1.6em;
}

header {
  position: absolute !important;
  background: transparent !important;
  z-index: 9;
}

section.car {
  position: relative;
  height: 480px;
  background-color: #000;
  background-image: url("../assets/images/detalhes-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
}

section.car .container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
}

section.info {
  min-height: 450px;
}

.versions-holder {
  position: relative;
  width: 100px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.versions-holder * {
  color: #fff;
}

.versions-holder p {
  margin: 0 15px;
}

.versions-holder svg {
  cursor: pointer;
}

h1.car-name {
  position: relative;
  font-size: 3.4em;
  color: var(--primary);
  text-align: right;
  max-width: 400px;
}

.car-holder {
  position: absolute;
  width: 800px;
  height: 390px;
  left: 0;
  bottom: -170px;
}

.car-holder img {
  width: 100%;
  margin-left: 50px;
}

.colors-holder {
  position: absolute;
  left: 0;
  bottom: -92px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.colors-holder .color {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  margin-top: 8px;
  border: 1px dashed #ccc;
  box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.colors-holder .color.active {
  border: 2px solid var(--primary);
}

section.info .container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.col1 {
  position: relative;
  width: 75%;
  padding: 25px;
  margin-top: 60px;
}

.col2 {
  position: relative;
  width: 25%;
  padding: 35px 25px;
  background-color: var(--primary);
  margin-top: -65px;
  border-radius: 0 0 3px 3px;
}

article.swiper-slide {
  padding-bottom: 35px;
}

article.swiper-slide h1 {
  font-size: 2em;
  text-align: center;
}

article.swiper-slide img {
  max-width: 100%;
  max-height: 140px;
  margin: 15px auto;
  border-radius: 3px;
  display: block;
}

article.swiper-slide p {
  font-size: 1.2em;
  font-weight: lighter;
  text-align: center;
}

.col1 ul {
  position: relative;
  widows: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.col1 ul li {
  position: relative;
  width: 31%;
  min-height: 32px;
  margin: 15px 2.33333% 0 0;
  display: flex;
  align-items: center;
  position: relative;
  padding: 15px 15px;
  font-size: 1.2em;
  border-bottom: 1px dashed #ccc;
}

.col1 ul li::before {
  position: absolute;
  content: "\2022";
  color: var(--primary);
  display: inline-block;
  width: 0.8em;
  margin-left: -0.9em;
}

section.plan {
  min-height: 300px;
  background-color: var(--primary);
}

.plans-holder {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 40px auto 0 auto;
}

.plans-holder .plan-card {
  position: relative;
  width: 25%;
  height: 220px;
  border-radius: 15px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px auto 0 auto;
  flex-direction: column;
  transition: background-color 0.2s ease, box-shadow 0.2s ease,
    transform 0.2s ease;
  cursor: pointer;
}

.plans-holder .plan-card.active {
  background-color: #000;
  box-shadow: 14px 20px 38px 0px rgba(0, 0, 0, 0.8);
  transform: scale(1.05);
}

.plans-holder .plan-card.active img {
  filter: saturate(0) brightness(100);
}

.plans-holder .plan-card .icon {
  position: absolute;
  margin-top: -10px;
}

.plans-holder .plan-card p.number {
  position: relative;
  font-size: 4em;
  color: var(--primary);
  text-align: center;
  left: -2px;
}

.plans-holder .plan-card p.label {
  position: absolute;
  bottom: 28px;
  font-size: 1.2em;
  text-align: center;
}

.plans-holder .plan-card.active p.label {
  color: #fff !important;
}

section.km {
  position: relative;
  min-height: 500px;
  background: url("../assets/images/kmbg.jpg") no-repeat;
  background-size: cover;
  background-position: center top;
}

.km-holder {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.km-holder .km-item {
  position: relative;
  width: 28%;
  padding: 28px 0;
  background-color: var(--primary);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 45px 0 0 0;
  box-shadow: 0 3px 10px -3px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease, box-shadow 0.2s ease,
    transform 0.2s ease;
  cursor: pointer;
}

.km-holder .km-item.active {
  background-color: #000;
  box-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
}

.km-holder .km-item.active span {
  color: var(--primary);
}

.km-holder .km-item p {
  font-size: 2.5em;
  color: #000;
}

.km-holder .km-item.active p {
  color: var(--primary);
}

.km-holder .km-item span {
  position: relative;
  margin: 8px 0 0 12px;
  font-size: 1.2em;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  color: #000;
}

.total {
  text-align: center;
  font-size: 1.6em;
}

.price {
  font-family: fontBold;
  position: relative;
  font-size: 3em;
  color: var(--primary);
  text-align: center;
  margin-top: 15px;
}

.form-holder {
  position: relative;
  width: 100%;
  max-width: 440px;
  margin: 35px auto 0 auto;
}

.value-holder {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #ccc;
}

button.btn {
  width: 100%;
  margin: 25px auto 0 auto;
  background-color: var(--primary);
  padding: 16px 25px;
}

button.btn:hover {
  background-color: #000;
}

button.btn:hover span {
  transition: color 0.2s ease;
}

button.btn:hover span {
  color: #fff;
}

.value-holder .input-label {
  position: relative;
  display: block;
  font-size: 1.2em;
  font-family: fontLight;
}

.value-holder input {
  font-family: fontRegular2;
  position: relative;
  width: 100%;
  height: 38px;
  font-size: 1.4em;
}

p.warning {
  font-family: fontRegular2;
  position: relative;
  font-size: 1.2em;
  text-align: center;
  margin: 25px auto 0 auto;
}

.form-holder.sent {
  margin-top: 0;
}

.form-holder.sent h1 {
  font-size: 2.4em;
  color: var(--primary);
}

.form-holder.sent p {
  font-family: fontRegular2;
  font-size: 1.2em;
  margin: 15px 0 0 0;
}

.contact-call {
  position: relative;
  font-size: 1.3em;
  font-family: fontRegular2;
  text-align: center;
  margin: 15px auto 0 auto;
  max-width: 440px;
  color: #444;
}

.no-opacity {
  opacity: 0 !important;
}

@media only screen and (max-width: 720px) {
  header {
    position: fixed !important;
    background: #000 !important;
    z-index: 999;
  }

  section.car {
    position: relative;
    background-color: #000;
    background-image: url("../assets/images/detalhes-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
  }

  .versions-holder {
    margin-top: 90px;
    width: initial;
  }

  section.car .container {
    height: 100%;
    display: block;
  }

  h1.car-name {
    margin-top: 15px;
    text-align: left;
  }

  .car-holder {
    position: relative;
    width: 120%;
    left: 0;
    margin: 50px 0 0 0;
    height: initial;
    bottom: initial;
  }

  .car-holder img {
    position: relative;
    width: 100%;
    margin-left: initial;
    left: -15%;
  }

  .colors-holder {
    bottom: 95px;
    left: initial;
    right: 0;
    width: 24px;
  }

  .colors-holder .color {
    width: 30px;
    height: 30px;
    margin: 15px 0 0 0;
  }

  section.info .container {
    flex-direction: column-reverse;
  }

  section.car {
    z-index: 2;
  }

  section.info {
    z-index: 1;
  }

  section.info::before {
    position: absolute;
    content: "";
    width: 100vw;
    height: 50px;
    background-color: #131312;
    top: 0;
    left: 0;
  }

  section.info .container .col1,
  section.info .container .col2 {
    width: 100%;
  }

  section.info .container .col1 {
    margin-top: 20px;
  }

  section.info .container .col2 {
    padding: 55px 25px 35px 25px;
    width: 100vw;
    margin-left: -5.5%;
    border-radius: 35px 35px 0 0;
  }

  article.swiper-slide h1 {
    text-align: center;
  }

  article.swiper-slide img {
    display: block;
    margin: 15px auto;
    max-height: 220px;
  }

  article.swiper-slide p {
    font-size: 1.3em;
    text-align: center;
  }

  .col1 ul li {
    width: 48%;
    min-height: 48px;
    font-size: 1.2em;
    margin: 0 0 0 0;
  }

  .col1 ul li:nth-child(2n + 2) {
    margin-left: 4%;
  }

  .plan-card {
    width: 46% !important;
  }

  .km-holder {
    position: relative;
    margin-top: 65px !important;
  }

  .km-holder .km-item {
    width: 49%;
    margin-top: 10px;
  }

  .km-holder .km-item span {
    margin-left: 5px;
  }
}

@media only screen and (min-width: 721px) and (max-width: 1159px) {
  .car-holder {
    width: 540px;
    bottom: -280px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
}
</style>
