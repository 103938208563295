<template>
  <div class="container large">
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div v-show="showForm" @click="showForm = false" class="bg-cover"></div>
    </transition>
    <transition
      enter-active-class="animated fadeInUp"
      leave-active-class="animated fadeOutDown"
    >
      <div v-show="showForm" class="form-holder">
        <div @click="showForm = false" class="close">X</div>
        <span>Carro de interesse</span>
        <p class="car-name">{{ objForm.selectedCar }}</p>

        <p class="text">
          Preencha o formulário abaixo e em breve entraremos em contato com
          você.
        </p>

        <div class="value-holder mt-1">
          <span class="input-label">Nome *</span>
          <input type="text" v-model="objForm.name" />
        </div>

        <div class="value-holder mt-1">
          <span class="input-label">E-mail *</span>
          <input type="text" v-model="objForm.email" />
        </div>

        <div class="value-holder mt-1">
          <span class="input-label">Telefone</span>
          <input-mask
            v-model="objForm.phoneNumber"
            :mask="[{ mask: '(00) 0000-0000' }, { mask: '(00) 00000-0000' }]"
          />
        </div>

        <div class="value-holder mt-1">
          <span class="input-label">Mensagem</span>
          <textarea type="text" v-model="objForm.message"></textarea>
        </div>

        <transition enter-active-class="animated fadeIn">
          <p class="warning mt-1" v-show="warningMessage != ''">
            {{ warningMessage }}
          </p>
        </transition>

        <transition enter-active-class="animated fadeIn">
          <p class="warning mt-1" v-show="formSent">
            Recebemos o seu contato. Obrigado.
          </p>
        </transition>

        <button @click="sendForm()" class="btn">
          <span v-show="!loading">ENVIAR</span>
          <div v-show="loading" class="loading2"></div>
        </button>
      </div>
    </transition>

    <div v-show="loadingOffers" class="flex-center mt-3">
      <div class="loading2"></div>
    </div>

    <transition enter-active-class="animated fadeIn">
      <div
        v-show="!loadingOffers"
        class="offer-cards-holder flex-center-mobile"
      >
        <div v-for="car in offers" :key="car.id" class="offer-card">
          <a
            :href="`#/${car.slug}/${car.selectedVersion.name}`"
            class="img-holder"
          >
            <img :src="car.image" :alt="car.name" />
          </a>
          <div class="content-holder">
            <div>
              <div v-show="car.version.length === 1" class="label">Modelo:</div>
              <div
                v-show="car.version.length > 1"
                class="label multiple"
                style="margin-left: -3%"
              >
                Escolha o modelo:
                <font-awesome-icon
                  class="select-arrow"
                  :icon="['fas', 'sort-down']"
                />
              </div>
              <h1 v-show="car.version.length === 1">
                {{ car.version[0].name }}
              </h1>

              <select
                @change="updateCarInfo(car)"
                v-model="car.selectedVersion"
                v-show="car.version.length > 1"
              >
                <option
                  v-for="version in car.version"
                  :key="version.id"
                  :value="version"
                >
                  {{ version.name }}
                </option>
              </select>

              <p class="small">A PARTIR DE:</p>
              <p
                v-if="car.selectedVersion.promotionalPrice > 0"
                class="price"
                :id="`price${car.id}`"
              >
                {{
                  $filters.currencyFilter(car.selectedVersion.promotionalPrice)
                }}
                <span>*</span>
              </p>
              <p v-else class="price price-consult" :id="`price${car.id}`">
                {{ car.selectedVersion.promotionalPrice }}
              </p>

              <ul :key="car.selectedVersion.id">
                <li
                  v-for="(feature, index) in car.selectedVersion.arrFeatures"
                  :key="index"
                >
                  <p>{{ feature }}</p>
                  <font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <li>
                  <p>...</p>
                  <font-awesome-icon
                    :icon="['fas', 'check']"
                    style="opacity: 0"
                  />
                </li>
              </ul>
            </div>

            <div>
              <a
                :href="`#/${car.slug}/${car.selectedVersion.name}`"
                class="btn more"
                >SAIBA MAIS</a
              >

              <button @click="chooseCar(car.selectedVersion)">
                TENHO INTERESSE
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <p class="conditions">
      * Valores para contratação de 12.000 km anuais no plano de 36 meses.
    </p>
    <p class="conditions second">* Veículos conforme disponibilidade do dia.</p>
  </div>
</template>
<script>
import http from "@/http";
import validators from "../util/validators";
import { IMaskComponent } from "vue-imask";

export default {
  name: "Ofertas",
  components: {
    "input-mask": IMaskComponent,
  },
  data() {
    return {
      offers: [],
      showForm: false,
      objForm: {
        selectedCar: "",
        selectedPlan: "Não Selecionado",
        selectedKm: "Não Selecionado",
        viewedPrice: 0,
        name: "",
        email: "",
        phoneNumber: "",
        message: "",
      },
      loading: false,
      warningMessage: "",
      formSent: false,
      loadingOffers: true,
    };
  },
  created() {
    http
      .get("car/list?take=-1")
      .then((response) => {
        this.offers = response.data.list;
        this.offers = this.offers.filter((car) => {
          if (car.version.length > 0) {
            car.version.map((el) => {
              if (el.promotionalPrice <= 0) {
                el.promotionalPrice = "Preço sob Consulta";
                this.$forceUpdate();
              }
            });
            car.selectedVersion = car.version[0];
            car.selectedVersion.arrFeatures =
              car.version[0].features.split(",");
            car.selectedVersion.arrFeatures =
              car.selectedVersion.arrFeatures.splice(0, 6);
            return car;
          }
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.loadingOffers = false;
      });
  },
  methods: {
    sendForm() {
      if (this.objForm.name == "") {
        this.warningMessage = "Por favor, informe o seu nome.";
        return;
      }
      if (!validators.validateEmail(this.objForm.email)) {
        this.warningMessage = "Por favor, informe um e-mail válido.";
        return;
      }
      this.loading = true;
      this.warningMessage = "";

      http
        .post("contact", this.objForm)
        .then((response) => {
          if (response.status === 200) {
            this.formSent = true;
            this.objForm = {
              selectedCar: "",
              selectedPlan: "Não Selecionado",
              selectedKm: "Não Selecionado",
              viewedPrice: 0,
              name: "",
              email: "",
              phoneNumber: "",
              message: "",
            };
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateCarInfo(car) {
      car.selectedVersion = car.version.filter(
        (el) => el.id === car.selectedVersion.id
      )[0];
      car.selectedVersion.arrFeatures = car.selectedVersion.features.split(",");
      car.selectedVersion.arrFeatures = car.selectedVersion.arrFeatures.splice(
        0,
        6
      );
      this.$forceUpdate();
    },

    chooseCar(car) {
      this.showForm = true;
      this.objForm.selectedCar = car.name;
      this.objForm.viewedPrice = car.promotionalPrice;
    },
  },
};
</script>
<style scoped>
.container.large {
  width: 90%;
  max-width: initial;
}
.offer-cards-holder {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 2em;
  flex-wrap: wrap;
}

.offer-cards-holder .offer-card {
  position: relative;
  width: 100%;
  padding-bottom: 10px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 9px 10px -4px rgba(0, 0, 0, 0.15);
}

.offer-card .img-holder {
  position: relative;
  display: block;
  width: 100%;
  height: 160px;
  background: linear-gradient(-30deg, transparent 50%, var(--primary) 50%);
}

.offer-card .img-holder img {
  position: absolute;
  max-width: 98%;
  max-height: 100%;
  top: 50%;
  left: 50%;
  right: 0;
  margin: 0 auto;
  transform: translate3d(-50%, -50%, 0);
  filter: saturate(0);
  transition: transform 0.4s ease;
}

.offer-card:hover .img-holder img {
  transform: translate3d(-50%, -50%, 0) scale(1.1);
  transform-origin: center center;
}

.offer-card .content-holder {
  position: relative;
  height: calc(100% - 160px);
  padding: 0 25px 25px 25px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.offer-card .content-holder h1,
.offer-card .content-holder select {
  position: relative;
  font-weight: bold;
  font-size: 1.8em;
  margin-bottom: 25px;
  z-index: 1;
}

.content-holder .label {
  position: relative;
  font-size: 1.2em;
  margin-top: 15px;
}

.content-holder .label .select-arrow {
  position: absolute;
  right: -3px;
  margin-top: 12px;
  font-size: 2em;
  animation-iteration-count: 20;
  animation-duration: 0.2s;
  animation-direction: alternate;
}

.content-holder .label.multiple::after {
  position: absolute;
  content: "";
  width: 0;
  height: 1px;
  left: 0;
  right: 0;
  margin: 55px auto 0 auto;
  background-color: var(--primary);
  transition: width 0.5s ease;
}

.offer-card:hover .label.multiple::after {
  width: 100%;
}

.offer-card:hover .label.multiple .select-arrow {
  animation-name: jump;
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-4px);
  }
}

.offer-card .content-holder select {
  position: relative;
  width: 100%;
  padding-right: 12px;
  left: -3%;
  background: transparent;
  appearance: none;
}

.offer-card .content-holder p.small {
  font-size: 0.9em;
}

.offer-card .content-holder p.price {
  position: relative;
  font-weight: bold;
  font-size: 2.6em;
  margin: 5px 0;
  color: var(--primary);
  z-index: 1;
}

.price-consult {
  font-size: 1.8em !important;
}

.offer-card .content-holder p.price span {
  position: relative;
  top: -14px;
  color: #777;
}

p.conditions {
  position: relative;
  margin: 35px 0 0 0;
  font-style: italic;
  color: #777;
}

p.conditions.second {
  margin-top: 10px;
}

.offer-card .content-holder ul {
  position: relative;
  margin: 20px 0 0 0;
  min-height: 277px;
  z-index: 1;
}

.offer-card .content-holder ul li {
  position: relative;
  font-size: 1.1em;
  font-weight: lighter;
  margin: 15px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.offer-card .content-holder ul li p {
  font-size: 1em;
  margin-right: 5px;
}

.offer-card .content-holder button,
a.more {
  position: relative;
  margin: 15px 0 0 0;
  padding: 14px 25px;
  border-radius: 3px;
  width: 100%;
  background-color: #000;
  color: var(--primary);
  z-index: 1;
  cursor: pointer;
  transition: color 0.4s ease, background-color 0.4s ease;
}

a.more {
  background-color: transparent;
  border: 1px solid var(--primary);
  display: block;
  text-align: center;
  color: #000;
  transition: background-color 0.3s ease, color 0.3s ease;
}

a.more:hover {
  background-color: #000;
  color: var(--primary);
}

.content-holder button::before {
  position: absolute;
  content: "";
  width: 60px;
  height: 2px;
  background-color: var(--primary);
  right: 0px;
  bottom: -30px;
  z-index: 0;
  transition: transform 0.4s ease, width 0.4s ease;
}

.content-holder button:hover {
  background-color: var(--primary);
  color: #000;
}

.content-holder button:hover::before {
  width: 100%;
}

.form-holder {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 420px;
  max-height: 100vh;
  padding: 25px 35px 35px 35px;
  background-color: var(--primary);
  border-radius: 3px 3px 0 0;
  right: 80px;
  margin: 0 auto;
  z-index: 9999;
  box-shadow: 3px -8px 14px -3px rgba(0, 0, 0, 0.3);
}

.form-holder .close {
  position: absolute;
  top: 25px;
  right: 35px;
  font-size: 1.4em;
  z-index: 2;
  cursor: pointer;
}

.form-holder span {
  position: relative;
  display: block;
  font-size: 1.1em;
}

.form-holder .car-name {
  position: relative;
  font-size: 1.4em;
  margin: 5px 0 0 0;
}

.form-holder .text {
  margin-top: 15px;
}

.form-holder .value-holder {
  position: relative;
  width: 100%;
}

.form-holder .value-holder span {
  position: relative;
  margin: 0 0 5px 15px;
}

.form-holder .value-holder input,
.form-holder .value-holder textarea {
  position: relative;
  width: 100%;
  height: 32px;
  border-radius: 3px;
  padding-left: 15px;
  background: rgba(255, 0, 0, 0.09);
  border-bottom: 1px solid #000;
}

.form-holder .value-holder textarea {
  padding-top: 10px;
  height: 64px;
  resize: none;
}

.form-holder .btn {
  background-color: #000 !important;
  padding: 15px 30px !important;
  margin: 10px 0 0 0 !important;
}

.form-holder .btn span {
  color: #fff;
}

@media only screen and (max-width: 1159px) {
  .label {
    margin-top: 25px;
  }
  .form-holder {
    right: 0;
    left: 0;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 720px) {
  .offer-card .content-holder ul {
    min-height: initial;
  }
}
</style>
