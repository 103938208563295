<template>
  <div>
    <Header @closeCalc="closeCalculator"></Header>

    <section class="intro" id="intro">
      <transition leave-active-class="animated fadeOut">
        <img
          v-show="!showCalculator && showIntroBg"
          class="section-intro-bg"
          id="sectionIntroBg"
          src=""
          alt="bg img"
          loading="lazy"
        />
      </transition>
      <transition leave-active-class="animated fadeOut">
        <img
          v-show="showCalculator"
          class="section-intro-bg dark"
          src="../assets/images/detalhes-bg.jpg"
          alt="bg img"
          loading="lazy"
        />
      </transition>
      <div>
        <div v-show="!showCalculator" class="container">
          <div class="blocks-holder">
            <transition
              leave-active-class="animated fadeOutLeft"
              enter-active-class="animated fadeInLeft"
            >
              <div v-show="showIntroTexts" class="block">
                <h1>QUEM FAZ<br />CONTA, ASSINA</h1>
              </div>
            </transition>
            <transition
              leave-active-class="animated fadeOutRight"
              enter-active-class="animated fadeInRight"
            >
              <div v-show="showIntroTexts" class="block">
                <p>
                  Se você ouve música, assiste filmes e acessa a internet
                  através de pacotes por assinatura, por que não fazer o mesmo
                  com um carro zero?<br /><br /><span
                    >Também para empresas.</span
                  >
                </p>
              </div>
            </transition>
            <transition
              leave-active-class="animated fadeOutDown"
              enter-active-class="animated fadeInLeft"
            >
              <div
                v-show="showIntroTexts && cheapestRentACarInstallment > 0"
                class="block"
              >
                <button @click="startCalculator()" class="btn simulate">
                  SIMULAR VALORES
                </button>
              </div>
            </transition>
          </div>
          <p class="img-legal-text">*imagem meramente ilustrativa</p>
        </div>
        <div class="intro-cars-holder" :class="{ desaturate: showCalculator }">
          <transition leave-active-class="animated fadeOutLeft">
            <img
              v-show="showCar1"
              src="../assets/images/home/kicks.png"
              alt="Nissan Kicks"
            />
          </transition>
          <transition leave-active-class="animated fadeOutLeft">
            <img
              v-show="showCar2"
              src="../assets/images/home/renegade.png"
              alt="Jeep Renegade"
            />
          </transition>
          <transition leave-active-class="animated fadeOutLeft">
            <img
              v-show="showCar3"
              src="../assets/images/home/toro.png"
              alt="Fiat Toro"
            />
          </transition>
          <transition leave-active-class="animated fadeOutRight">
            <img
              v-show="showCar4"
              src="../assets/images/home/compass.png"
              alt="Jeep Compass"
            />
          </transition>
          <transition leave-active-class="animated fadeOutRight">
            <img
              v-show="showCar3"
              src="../assets/images/home/commander.png"
              alt="Jeep Commander"
            />
          </transition>
        </div>
        <transition enter-active-class="animated zoomIn">
          <div v-show="showCalculator" class="calc-holder-all">
            <div
              class="container"
              :class="{ 'flex-center-desktop': calcShowResult }"
            >
              <article
                class="calc-step1-holder"
                :class="{ center: !calcAdjustSpace }"
              >
                <h1>SIMULE</h1>
                <p>
                  Informe o valor do veículo que está querendo comprar e
                  descubra por que compensa alugar.
                </p>
                <!-- <h2>Método de compra</h2>
                <div class="radios-holder">
                  <div class="radio-holder">
                    <input
                      @change="buyInCashChange()"
                      v-model="buyInCash"
                      type="radio"
                      name="purchase-method"
                      id="opt1"
                      :value="true"
                    />
                    <label for="opt1">À Vista</label>
                  </div>
                  <div class="radio-holder">
                    <input
                      @change="buyInCashChange()"
                      v-model="buyInCash"
                      type="radio"
                      name="purchase-method"
                      id="opt2"
                      :value="false"
                    />
                    <label for="opt2">Parcelado</label>
                  </div>
                </div> -->
                <div v-show="buyInCash" class="value-holder">
                  <span class="input-label">Valor total do veículo</span>
                  <money v-model="calcValues.carValue" />
                  <transition enter-active-class="animated fadeIn">
                    <p v-show="warningMessage != ''" class="warning">
                      {{ warningMessage }}
                    </p>
                  </transition>
                </div>
                <div v-show="!buyInCash" class="value-holder">
                  <span class="input-label">Valor da parcela</span>
                  <money v-model="calcValues.installmentValue" />

                  <span class="input-label ipva">Valor do IPVA</span>
                  <money v-model="calcValues.ipva" />
                  <p v-show="warningMessage != ''" class="warning">
                    {{ warningMessage }}
                  </p>
                </div>
                <button @click="calcSimulate()" class="btn calc offers-all">
                  REALIZAR SIMULAÇÃO
                </button>
                <transition enter-active-class="animated fadeInLeft">
                  <ul v-show="calcShowResult" class="taxes-holder">
                    <li>
                      <p>4% de IPVA</p>
                      <p class="val">
                        {{ $filters.currencyFilter(expenses.ipva) }}
                      </p>
                    </li>
                    <li>
                      <p>3% de Seguro</p>
                      <p class="val">
                        {{ $filters.currencyFilter(expenses.insurance) }}
                      </p>
                    </li>
                    <li>
                      <p>15% Depreciação 1º Ano</p>
                      <p class="val">
                        {{
                          $filters.currencyFilter(
                            expenses.depreciationFirstYear
                          )
                        }}
                      </p>
                    </li>
                    <li>
                      <p>10% Depreciação Demais Anos</p>
                      <p class="val">
                        {{
                          $filters.currencyFilter(
                            expenses.depreciationOtherYears
                          )
                        }}
                      </p>
                    </li>
                    <li>
                      <p>Manutenção Anual</p>
                      <p class="val">
                        {{ $filters.currencyFilter(expenses.maintenance) }}
                      </p>
                    </li>
                  </ul>
                </transition>
              </article>
              <transition enter-active-class="animated zoomIn">
                <article v-show="calcShowResult" class="calc-result-holder">
                  <p class="topic">Comprando o carro</p>
                  <div class="topic-card negative flex-between">
                    <div class="block">
                      <p class="title">EM 1 ANO</p>
                      <span v-show="calcShowResult && buyInCash"
                        >Você perde:</span
                      >
                      <span v-show="calcShowResult && !buyInCash"
                        >Você gasta:</span
                      >
                      <p class="val">
                        - {{ $filters.currencyFilter(oneYearLoss) }}
                      </p>
                    </div>
                    <div class="block">
                      <p class="title">EM 3 ANOS</p>
                      <span v-show="calcShowResult && buyInCash"
                        >Você perde:</span
                      >
                      <span v-show="calcShowResult && !buyInCash"
                        >Você gasta:</span
                      >
                      <p class="val">
                        - {{ $filters.currencyFilter(threeYearsLoss) }}
                      </p>
                    </div>
                  </div>

                  <p class="topic">Ou você poderia</p>
                  <div class="topic-card result">
                    <p
                      v-show="cheapestRentACarInstallment * 12 < oneYearLoss"
                      class="benefits"
                    >
                      Alugar um Versa 0km por
                      {{
                        $filters.currencyFilter(cheapestRentACarInstallment)
                      }}, economizar
                      <b>{{
                        $filters.currencyFilter(
                          oneYearLoss - cheapestRentACarInstallment * 12
                        )
                      }}</b>
                      no primeiro ano e ainda:
                    </p>

                    <p
                      v-show="cheapestRentACarInstallment * 12 >= oneYearLoss"
                      class="benefits"
                    >
                      Alugar um Versa 0km a partir de
                      <b
                        >{{
                          $filters.currencyFilter(cheapestRentACarInstallment)
                        }}/mês</b
                      >
                      e ainda:
                    </p>

                    <ul>
                      <li>
                        <img
                          src="../assets/images/icon-check.png"
                          loading="lazy"
                          alt="benefit"
                        />
                        <p>
                          Guardar
                          <b>{{
                            $filters.currencyFilter(calcValues.carValue)
                          }}</b>
                          de aporte
                        </p>
                      </li>
                      <li>
                        <img
                          src="../assets/images/icon-check.png"
                          alt="benefit"
                          loading="lazy"
                        />
                        <p>Todo ano você pega um carro 0km</p>
                      </li>
                      <li>
                        <img
                          src="../assets/images/icon-check.png"
                          alt="benefit"
                          loading="lazy"
                        />
                        <p>Proteção inclusa</p>
                      </li>
                      <li>
                        <img
                          src="../assets/images/icon-check.png"
                          alt="benefit"
                          loading="lazy"
                        />
                        <p>Não se preocupe com IPVA e manutenções</p>
                      </li>
                    </ul>
                    <button
                      @click="scrollToElement('#offers')"
                      data-v-fae5bece=""
                      class="btn calc see-offers offers-all"
                    >
                      VER OFERTAS
                    </button>
                  </div>
                </article>
              </transition>
            </div>
          </div>
        </transition>
      </div>
    </section>

    <section class="intro-items">
      <div class="container">
        <div class="intro-items-holder">
          <div class="intro-item">
            <img src="../assets/images/item1.png" alt="carro" loading="lazy" />
            <p>
              Carro zero sem se preocupar com IPVA, documento, seguro ou
              manutenção.
            </p>
          </div>
          <div class="intro-item">
            <img
              src="../assets/images/item2.png"
              alt="parcelas"
              loading="lazy"
            />
            <p>Carro zero com<br />parcelas fixas.</p>
          </div>
          <div class="intro-item">
            <img src="../assets/images/item3.png" alt="tempo" loading="lazy" />
            <p>Carro zero por<br />12 ou 24 meses.</p>
          </div>
        </div>
      </div>
    </section>

    <section class="steps" id="steps">
      <div class="container">
        <div class="arrows1"></div>
        <div class="arrows2"></div>

        <p class="section-title">
          VEJA COMO
          <br />É FÁCIL ASSINAR
        </p>

        <div class="steps-holder">
          <div class="step-card">
            <p class="step-number">01.</p>
            <div class="img-holder">
              <img
                class="step1-img"
                src="../assets/images/step1.png"
                alt="passo 1"
                loading="lazy"
              />
            </div>
            <p class="action">ESCOLHA SEU CARRO</p>
          </div>
          <div class="step-card">
            <p class="step-number">02.</p>
            <div class="img-holder">
              <img
                src="../assets/images/step2.png"
                alt="passo 2"
                loading="lazy"
              />
            </div>
            <p class="action">ESCOLHA SEU PLANO ANUAL</p>
          </div>

          <div class="step-card">
            <p class="step-number">03.</p>
            <div class="img-holder">
              <img
                src="../assets/images/step3.png"
                alt="passo 3"
                loading="lazy"
              />
            </div>
            <p class="action">ESCOLHA SEU PACOTE DE KM</p>
          </div>
        </div>

        <button
          @click="scrollToElement('#offers')"
          data-v-fae5bece=""
          class="btn calc see-offers offers-all"
        >
          COMECE AQUI!
        </button>
      </div>
    </section>

    <section class="armored">
      <div class="container">
        <span class="text-center-mobile">BLINDADOS NÍVEL 3A DISPONÍVEIS!</span>
        <a
          href="http://wa.me/551937561877"
          target="_blank"
          class="whatsapp-holder"
        >
          <font-awesome-icon :icon="['fab', 'whatsapp']" />
          <span>Consulte-nos</span>
        </a>
      </div>
    </section>

    <section class="offers-banner">
      <div class="container">
        <p class="section-title black-line line-130">OFERTAS</p>
      </div>

      <picture>
        <source
          media="(max-width:720px)"
          :srcset="bannerOffers.imageMobile"
          alt="banner ofertas"
        />
        <source
          media="(min-width:721px)"
          :srcset="bannerOffers.image"
          alt="banner ofertas"
        />
        <img
          class="offer-banner"
          :src="rightBanner(bannerOffers)"
          alt="banner ofertas"
          loading="lazy"
        />
      </picture>
    </section>

    <section class="offers" id="offers">
      <div class="container">
        <Ofertas></Ofertas>
      </div>
    </section>

    <section class="about" id="about">
      <div class="container">
        <article class="about-holder">
          <div class="img-holder desktop6 tablet6 mobile12">
            <img
              src="../assets/images/fachada.jpg"
              alt="Rent a Car Loja"
              loading="lazy"
            />
          </div>
          <div class="content-holder desktop6 tablet6 mobile12">
            <div class="arrow-left"></div>
            <h1>
              SOBRE DAHRUJ
              <br />RENT A CAR
            </h1>
            <p>
              Agora você pode alugar um carro com todas as garantias,
              facilidades e ofertas que só o Grupo Dahruj, o maior grupo de
              concessionárias do interior de São Paulo pode oferecer.<br /><br />
              Com uma frota multimarcas sempre nova, serviços inovadores como o
              Carro Zero por Assinatura e vantagens exclusivas, a Dahruj Rent a
              Car é a sua locadora!
            </p>
          </div>
        </article>
      </div>
    </section>

    <section class="offers">
      <div class="container socials">
        <div class="socialwidget-holder mobile12">
          <div class="subtitle text-center text-red mb-1">FACEBOOK</div>
          <div class="desktop12 flex-center">
            <div
              class="fb-page desktop12 tablet12 mobile12"
              data-href="https://www.facebook.com/dahrujrentacar/"
              data-tabs="timeline"
              data-width="500"
              data-height
              data-small-header="false"
              data-adapt-container-width="false"
              data-hide-cover="false"
              data-show-facepile="true"
            >
              <blockquote
                cite="https://www.facebook.com/dahrujrentacar/"
                class="fb-xfbml-parse-ignore"
              >
                <a
                  href="https://www.facebook.com/dahrujrentacar/"
                  rel="noreferrer"
                  >Dahruj Rent a Car</a
                >
              </blockquote>
            </div>
          </div>
        </div>
        <div
          class="socialwidget-holder tablet10 mobile12 mt-3-tablet mt-3-mobile"
        >
          <div class="subtitle text-center text-red mb-1">INSTAGRAM</div>
          <iframe
            src="https://cdn.lightwidget.com/widgets/bc4523ddbdeb5cbe91c5244b26e1807b.html"
            scrolling="no"
            allowtransparency="true"
            class="lightwidget-widget"
            style="width: 100%; border: 0; overflow: hidden"
          ></iframe>
        </div>
      </div>
    </section>

    <!-- <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-show="showBtnGoTop"
        class="btn-go-up mobile-hidden"
      >
        <font-awesome-icon :icon="['fas', 'chevron-up']" />
      </div>
    </transition> -->

    <Footer></Footer>
  </div>
</template>

<script>
import http from "@/http";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Ofertas from "../components/Ofertas";

function initialData() {
  return {
    // showBtnGoTop: false,
    showIntroTexts: true,
    showCar1: true,
    showCar2: true,
    showCar3: true,
    showCar4: true,
    showCalculator: false,
    warningMessage: "",
    buyInCash: true,
    bannerOffers: {
      image: null,
      imageMobile: null,
    },
    expenses: {
      ipva: 0,
      insurance: 0,
      depreciationFirstYear: 0,
      depreciationOtherYears: 0,
      maintenance: 0,
    },
    oneYearLoss: null,
    threeYearsLoss: null,
    calcValues: {
      carValue: 0,
      installmentValue: 0,
      ipva: 0,
    },
    calcShowResult: false,
    calcAdjustSpace: false,
    showIntroBg: false,
    cheapestRentACarInstallment: 0,
  };
}

export default {
  name: "Home",
  data() {
    return initialData();
  },
  components: {
    Header,
    Footer,
    Ofertas,
  },
  created() {
    this.getBannerOffers();
    this.getMinPrice();
  },
  mounted() {
    const sectionIntroBg = document.getElementById("sectionIntroBg");
    const img = new Image();
    img.src = require("../assets/images/section-intro-bg.jpg");

    img.onload = () => {
      sectionIntroBg.src = img.src;
      this.showIntroBg = true;
    };
  },
  methods: {
    getBannerOffers() {
      http
        .get("banner")
        .then((response) => {
          this.bannerOffers = response?.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    rightBanner(bannerObj) {
      return window.innerWidth > 721 ? bannerObj.image : bannerObj.imageMobile;
    },
    getMinPrice() {
      http
        .get("pricing/minprice")
        .then((response) => {
          this.cheapestRentACarInstallment = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeCalculator() {
      this.calcShowResult = false;
      this.calcAdjustSpace = false;
      this.showCalculator = false;
      this.warningMessage = "";
      setTimeout(() => {
        this.showIntroTexts = true;
      }, 400);
    },
    buyInCashChange() {
      this.calcShowResult = false;
      this.calcAdjustSpace = false;
      this.warningMessage = "";
    },
    scrollToElement(el) {
      const distance = document.querySelector(el).offsetTop - 90;
      window.scroll({
        top: distance,
        left: 0,
        behavior: "smooth",
      });
    },
    calcSimulate() {
      if (this.buyInCash && this.calcValues.carValue === 0) {
        this.warningMessage = "Informe o valor de compra do veículo.";
        return;
      }

      if (
        !this.buyInCash &&
        (this.calcValues.installmentValue === 0 || this.calcValues.ipva === 0)
      ) {
        this.warningMessage = "Informe o valor da parcela e do IPVA do carro.";
        return;
      }

      let carValue;
      // Depreciação, IPVA, Seguro, Manutenção, Documentação (1º ano apenas)
      let depreciation = 0.15;
      const ipva = 0.04;
      const insurance = 0.03;
      const maintenance = 800;
      const documents = 150;
      let yearExpense = 0; // despesa no ano
      let totalExpenses = 0; // Soma das despesas ao longo dos anos
      if (this.buyInCash) {
        carValue = this.calcValues.carValue;
      } else {
        carValue = this.calcValues.ipva / 0.04;
      }

      this.expenses.ipva = carValue * ipva;
      this.expenses.insurance = carValue * insurance;
      this.expenses.depreciationFirstYear = carValue * depreciation;
      this.expenses.depreciationOtherYears = carValue * 0.1;
      this.expenses.maintenance = maintenance;

      for (let i = 1; i < 4; i++) {
        if (i === 1) {
          yearExpense += documents;
        } else {
          depreciation = 0.1;
        }
        yearExpense +=
          carValue * depreciation +
          carValue * ipva +
          carValue * insurance +
          maintenance;
        if (!this.buyInCash) {
          yearExpense += this.calcValues.installmentValue * 12;
        }
        totalExpenses += yearExpense;
        // console.log("Ano ", i, " despesa de R$", yearExpense);
        carValue -= carValue * depreciation;
        yearExpense = 0;
        if (i === 1) {
          this.oneYearLoss = totalExpenses;
        }
      }
      this.threeYearsLoss = totalExpenses;
      // console.log("Despesa total: R$", totalExpenses);

      this.warningMessage = "";
      this.calcAdjustSpace = true;

      let smallScreen;
      if (window.innerWidth < 720) {
        smallScreen = true;
      } else {
        smallScreen = false;
      }

      setTimeout(() => {
        this.calcShowResult = true;
        if (smallScreen) {
          window.scroll({
            top: 440,
            left: 0,
            behavior: "smooth",
          });
        }
      }, 500);
    },
    startCalculator() {
      this.showIntroTexts = false;
      this.showCalculator = true;
      // const carsIndex = 1;
      // const myInterval = setTimeout(() => {
      //   if (carsIndex < 5) {
      //     this["showCar" + carsIndex] = false;
      //   } else {
      //   this.showCalculator = true;
      //     clearInterval(myInterval);
      //   }
      //   carsIndex++;
      // }, 500);
    },
  },
};
</script>
<style lang="scss" scoped>
@media only screen and (min-width: 1160px) {
  .socialwidget-holder {
    width: 46%;
  }
}

.socials {
  display: flex;
  justify-content: space-between;
}

.text-center {
  text-align: center;
}

.subtitle {
  position: relative;
  font-size: 2.2em;
  font-family: fontRegular;
  font-weight: lighter;
}

.offer-banner {
  position: relative;
  max-width: 100vw;
  display: block;
  margin: 5rem auto 0 auto;
}

@media only screen and (min-width: 721px) and (max-width: 1099px) {
  .subtitle {
    position: relative;
    font-size: 2.2em;
    font-family: fontRegular;
  }

  .fb_iframe_widget {
    width: 100% !important;
    max-width: 100% !important;
    overflow: hidden;
  }

  .socials {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}

.img-legal-text {
  position: absolute;
  width: 100%;
  margin: 0;
  font-style: italic;
  color: #666;
  bottom: -310px;
  text-align: left;
  z-index: 99;
}

/* .btn-go-up {
  position: fixed;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  right: 3vw;
  bottom: 3vw;
  cursor: pointer;
}

.btn-go-up svg {
  position: relative;
  font-size: 1.6em;
  color: var(--primary);
} */

section.intro {
  min-height: 510px;
  background-color: #000;
  margin-top: 90px;
}

img.section-intro-bg {
  position: absolute;
  min-width: 100vw;
  max-width: 400vw;
  max-height: 100%;
  margin: 0 auto;
  top: 0;
  left: 50%;
  right: 0;
  transform: translateX(-50%);
}

img.section-intro-bg.dark {
  min-width: initial;
  max-width: initial;
  max-height: initial;
  height: 550px;
  opacity: 0.7;
}

.blocks-holder {
  position: relative;
  width: 900px;
  min-height: 180px;
  max-width: 100%;
  margin: 42px auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.blocks-holder .block {
  position: relative;
  width: 50%;
  padding: 0 25px;
  height: 90px;
}

.blocks-holder .block h1 {
  text-align: right;
  font-size: 3em;
}

.blocks-holder .block p {
  position: relative;
  display: block;
  top: 5px;
  font-size: 1.6em;
}

.blocks-holder .block p span {
  font-size: 1em;
  font-weight: bold;
}

.blocks-holder .block button.btn {
  display: block;
  margin-left: auto;
  padding: 16px 50px;
  font-size: 1.1em;
}

.intro-cars-holder {
  position: absolute;
  width: 100vw;
  max-width: 1280px;
  height: 260px;
  display: flex;
  bottom: 0;
  justify-content: space-between;
  align-items: bottom;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: filter 0.3s ease();
  img {
    position: absolute;
    display: block;
    height: 80%;
    bottom: -70px;
  }
}

.intro-cars-holder img:nth-child(1) {
  left: -8%;
  bottom: -60px;
}

.intro-cars-holder img:nth-child(2) {
  left: 14%;
  height: 100%;
  bottom: -120px;
}
.intro-cars-holder img:nth-child(3) {
  left: 40%;
  bottom: -140px;
  height: 115%;
  z-index: 2;
}
.intro-cars-holder img:nth-child(4) {
  left: 57%;
  height: 110%;
  bottom: -100px;
  z-index: 1;
}
.intro-cars-holder img:nth-child(5) {
  left: 79%;
  height: 87%;
  bottom: -58px;
}

section.intro-items {
  padding-bottom: 35px;
}

.intro-items-holder {
  position: relative;
  width: 780px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 90px auto 0 auto;
}

.intro-items-holder .intro-item {
  position: relative;
  width: 33%;
  padding: 35px 25px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
}

.intro-items-holder .intro-item:nth-child(2)::before,
.intro-items-holder .intro-item:nth-child(2):after {
  position: absolute;
  content: "";
  width: 1px;
  height: 70%;
  top: 15%;
  left: 0;
  background-color: var(--primary);
}

.intro-items-holder .intro-item:nth-child(2):after {
  left: initial;
  right: 0;
}

.intro-items-holder .intro-item img {
  position: relative;
  max-height: 84px;
}

.intro-items-holder .intro-item p {
  position: relative;
  text-align: center;
  margin-top: 25px;
  font-size: 1.4em;
}

section.steps {
  background-color: #000;
}

.arrows1,
.arrows2 {
  position: absolute;
  width: 4000px;
  height: 440px;
  background: url("../assets/images/arrows-bg1.png") repeat-x;
  background-size: initial;
  left: -2000px;
  top: -10px;
  animation: goRight 400s linear infinite;
  opacity: 0.5;
}

.arrows2 {
  background: url("../assets/images/arrows-bg2.png") repeat-x;
  height: 458px;
  animation: goRight 640s linear infinite;
}

.steps-holder {
  position: relative;
  width: 100%;
  margin: 50px 0 0 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.steps-holder .step-card {
  position: relative;
  min-width: 300px;
  max-width: 450px;
  padding: 25px;
  background-color: var(--primary);
  border-radius: 20px;
  margin: 65px auto 0 auto;
}

.step-card .step-number {
  position: relative;
  font-weight: bold;
  color: #fff;
  font-size: 7em;
  text-shadow: 0 1px #000;
  margin: -70px 0 0 0;
  text-align: center;
}

.step-card .img-holder {
  position: relative;
  width: 100%;
  height: 70px;
  margin: 15px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-card .img-holder img {
  max-width: 140px;
  max-height: 90%;
}

img.step1-img {
  max-width: 110px !important;
}

.step-card .action,
.step-card .subtitle {
  position: relative;
  text-align: center;
  font-size: 1.4em;
}

.step-card .subtitle {
  margin-top: 15px;
  font-size: 1.2em;
}

.step-card ul {
  position: relative;
  margin: 15px auto 0 auto;
  width: 186px;
}

.step-card ul li {
  position: relative;
  font-size: 1.2em;
  left: 5px;
  margin-top: 5px;
}

.step-card ul li::before {
  position: absolute;
  content: "\2022";
  color: #fff;
  display: inline-block;
  width: 0.8em;
  margin-left: -0.9em;
}

section.armored {
  background-color: var(--gray1);
  padding: 65px 0 0 0;
}
section.armored .container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
@media only screen and (max-width: 720px) {
  section.armored .container {
    flex-direction: column;
  }
}
section.armored span {
  font-size: 1.7rem;
}
section.armored .whatsapp-holder {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 38px;
  border-radius: 6px;
  background-color: #25d366;
  gap: 10px;
  span {
    font-size: 1.3rem;
    color: #fff;
  }
  svg {
    font-size: 1.6rem;
    color: #fff;
  }
}
section.offers-banner {
  background-color: var(--gray1);
}
section.offers {
  background-color: var(--gray1);
}

button.offers-all {
  position: relative;
  display: block;
  margin: 55px auto 0 auto;
  padding: 15px 42px;
  border-radius: 3px;
  background-color: var(--primary);
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
}

button.offers-all::before,
button.offers-all::after {
  position: absolute;
  content: "";
  width: 0;
  height: 2px;
  top: -5px;
  left: -5px;
  background-color: var(--primary);
  transition: width 0.3s ease;
}

button.offers-all::after {
  top: initial;
  left: initial;
  bottom: -5px;
  right: -5px;
}

button.offers-all:not(.calc):hover {
  background-color: #000;
  color: #fff;
}

button.offers-all:hover::before,
button.offers-all:hover::after {
  width: 60px;
}

section.about {
  background-color: var(--gray1);
}
.about-holder {
  position: relative;
  width: 100%;
  background-color: var(--primary);
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 10px 14px -6px rgba(0, 0, 0, 0.2);
  .img-holder {
    position: relative;
    height: 30vw;
    max-height: 500px;
    min-height: 260px;
    overflow: hidden;
    img {
      position: absolute;
      min-width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      right: 0;
      margin: 0 auto;
      transform: translate3d(-50%, -50%, 0);
    }
  }
}

.about-holder .content-holder {
  position: relative;
  padding: 45px;
  z-index: 1;
}

.about-holder .content-holder h1 {
  font-size: 2.6em;
}

.about-holder .content-holder p {
  margin-top: 25px;
  font-size: 1.3em;
  font-weight: lighter;
}

.calc-step1-holder {
  position: relative;
  width: 360px;
  margin: 0;
  transition: transform 0.3s ease;
}

.calc-step1-holder.center {
  transform: translateX(103%);
}

.calc-step1-holder h1,
.calc-step1-holder h2 {
  position: relative;
  font-size: 2.2em;
  color: var(--primary);
  text-align: center;
  letter-spacing: 2px;
}

.calc-step1-holder h2 {
  font-size: 1.2em;
}

.calc-step1-holder p {
  position: relative;
  text-align: center;
  color: #fff;
  margin: 15px 25px;
  font-size: 1.2em;
}

.calc-step1-holder p.warning {
  color: #f00;
}

.calc-step1-holder .radios-holder {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.calc-step1-holder .radios-holder .radio-holder {
  position: relative;
  width: 45%;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calc-step1-holder .radios-holder .radio-holder label {
  position: relative;
  color: #fff;
  margin-left: 10px;
}

.calc-step1-holder .value-holder {
  position: relative;
  width: 100%;
  max-width: 330px;
  margin: 15px auto 0 auto;
}

.calc-step1-holder .value-holder .input-label {
  position: relative;
  color: #ffc907;
  margin-left: 15px;
  display: block;
}

.calc-step1-holder .value-holder .input-label.ipva {
  margin-top: 15px;
}

.calc-step1-holder .value-holder input {
  position: relative;
  width: 100%;
  height: 42px;
  background: transparent;
  border-bottom: 2px solid #444;
  padding-left: 15px;
  color: #fff;
  font-size: 1.2em;
}

.calc-step1-holder .btn {
  position: relative;
  display: block;
  margin: 25px auto 0 auto;
  background-color: #1c1c1c;
  color: #fff;
}

.calc-step1-holder ul {
  position: relative;
  width: 100%;
  padding: 15px;
  background-color: #1c1c1c;
  margin-top: 35px;
}

.calc-step1-holder ul li {
  position: relative;
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calc-step1-holder ul li p {
  font-size: 0.9em !important;
  font-weight: lighter;
}

.calc-step1-holder ul li p.val {
  color: var(--primary);
}

.calc-result-holder {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin-left: 80px;
}

.calc-result-holder p.topic {
  position: relative;
  font-size: 1.2em;
  color: var(--primary);
  text-align: center;
}

.calc-result-holder .topic-card {
  position: relative;
  width: 100%;
  padding: 35px 0;
  background: #1c1c1c;
  margin: 25px 0;
  box-shadow: 2px 9px 10px -4px rgba(0, 0, 0, 0.4);
}

.calc-result-holder .topic-card.result {
  padding: 35px;
}

.topic-card.negative::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 60px;
  background-color: #000;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
}

.calc-result-holder .topic-card .block {
  position: relative;
  width: 50%;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.calc-result-holder .topic-card .block p.title {
  position: relative;
  font-size: 1.6em;
  color: #fff;
}

.calc-result-holder .topic-card .block span {
  position: relative;
  color: #fff;
  margin: 15px 0 5px 0;
}

.calc-result-holder .topic-card .block p.val {
  position: relative;
  font-size: 1.6em;
  font-weight: bold;
  color: rgb(204, 25, 25);
}

.calc-result-holder .topic-card p.benefits {
  position: relative;
  font-size: 1.4em;
  color: var(--primary);
}

.calc-result-holder .topic-card p.benefits b {
  color: var(--primary);
  font-size: 1em;
}

.calc-result-holder .topic-card ul {
  position: relative;
  margin-top: 25px;
}

.calc-result-holder .topic-card ul li {
  position: relative;
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: flex-start;
  align-items: center;
}

.calc-result-holder .topic-card ul li img {
  max-width: 16px;
  margin-right: 15px;
}

.calc-result-holder .topic-card ul li p {
  font-size: 1.2em;
  color: #fff;
}

.calc-result-holder .topic-card ul li p b {
  font-size: 1em;
  color: var(--primary);
}

.topic-card .btn {
  margin-top: 25px;
  margin-left: 0;
  background-color: var(--primary);
  color: #000;
}

@media only screen and (min-width: 721px) and (max-width: 1159px) {
  .about-holder .img-holder {
    height: initial;
  }

  .about-holder .img-holder img {
    left: initial;
    top: initial;
    transform: translate3d(0, 0, 0);
  }

  .calc-step1-holder {
    margin: 0 auto;
  }

  .calc-step1-holder.center {
    transform: none;
  }

  .calc-holder-all .container {
    display: flex;
    justify-content: space-between;
    width: 96%;
  }

  .calc-result-holder {
    max-width: 370px;
    margin-left: initial;
  }

  .intro-cars-holder {
    position: absolute;
    width: 100vw;
    max-width: 1280px;
    height: 200px;
    display: flex;
    bottom: 0;
    justify-content: space-between;
    align-items: bottom;
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: filter 0.3s ease();
  }

  .intro-cars-holder img {
    position: absolute;
    display: block;
    height: 72%;
    bottom: -70px;
  }

  .intro-cars-holder img:nth-child(1) {
    left: -9%;
  }

  .intro-cars-holder img:nth-child(2) {
    left: 10%;
  }
  .intro-cars-holder img:nth-child(3) {
    left: 28%;
    top: 144px;
  }
  .intro-cars-holder img:nth-child(4) {
    left: 55%;
    z-index: 2;
    top: 140px;
  }
  .intro-cars-holder img:nth-child(5) {
    left: 68%;
    height: 90%;
    bottom: -77px;
  }
}

@media only screen and (max-width: 720px) {
  .subtitle {
    font-family: fontRegular;
    position: relative;
    font-size: 2.2em;
  }

  .fb_iframe_widget {
    width: 100% !important;
    max-width: 100% !important;
    overflow: hidden;
  }

  .socials {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .intro-items-holder .intro-item {
    justify-content: flex-start;
  }

  .img-legal-text {
    bottom: -293px;
  }
  section.intro {
    min-height: 480px;
    margin-top: 45px;
    padding-top: 85px;
  }

  img.section-intro-bg.dark {
    top: initial;
    bottom: 0;
  }

  .blocks-holder {
    margin-top: 0;
    min-height: 204px;
  }

  .blocks-holder .block {
    width: 100%;
    height: 102px;
    h1 {
      text-align: left;
    }
  }

  .blocks-holder .block:first-child {
    padding: 0 10px 0 0;
  }

  .blocks-holder .block:nth-child(2) {
    padding: 0 0 0 10px;
  }

  .blocks-holder .block:last-child {
    padding: 0 10px 0 0;
  }

  .blocks-holder .block button.btn {
    margin-top: 35px;
  }

  .blocks-holder .block button.btn {
    padding: 16px 20px;
    font-size: 1em;
  }

  .intro-cars-holder img {
    height: 80%;
    bottom: -40px;
  }

  .blocks-holder .block h1 {
    font-size: 2.4em;
  }

  .blocks-holder .block p {
    position: relative;
    bottom: initial;
    font-size: 1.4em;
  }

  .intro-cars-holder {
    height: 150px;
  }

  .intro-cars-holder img:nth-child(1) {
    left: 46%;
    height: 86%;
    top: 25px;
  }

  .intro-cars-holder img:nth-child(2) {
    width: initial;
    left: 57%;
    height: 110%;
    top: 63px;
  }
  .intro-cars-holder img:nth-child(3) {
    display: none;
  }
  .intro-cars-holder img:nth-child(4) {
    width: initial;
    height: 110%;
    left: -8%;
    top: 77px;
  }
  .intro-cars-holder img:nth-child(5) {
    height: 90%;
    left: -16%;
    top: 30px;
  }

  .intro-items-holder {
    margin-top: 45px;
    flex-wrap: wrap;
  }

  .intro-items-holder .intro-item {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
  }

  .intro-items-holder .intro-item img {
    max-height: 74px;
  }

  .intro-items-holder .intro-item p {
    text-align: left;
    margin: 0 0 0 25px;
    font-size: 1.4em;
  }

  .intro-items-holder .intro-item::before,
  .intro-items-holder .intro-item::after {
    position: absolute;
    content: "";
    width: 1px;
    height: 70%;
    top: 15%;
    left: 0;
    background-color: var(--primary);
  }

  .intro-items-holder .intro-item::after {
    left: initial;
    right: 0;
  }

  .calc-step1-holder {
    margin: 0 auto;
  }

  .calc-step1-holder.center {
    transform: none;
  }

  .calc-result-holder {
    margin: 35px auto 0 auto;
  }

  .calc-result-holder .topic-card ul li {
    height: initial;
    margin-top: 15px;
  }
}

@keyframes goRight {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 20000px 0;
  }
}
</style>
