<template>
  <div>
    <Header></Header>

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div v-show="loadingFull" class="full-loading">
        <div class="loading2"></div>
      </div>
    </transition>

    <section class="offers">
      <div class="container">
        <p class="section-title black-line">
          PERGUNTAS
          <br />FREQUENTES
        </p>
        <div class="faq-holder mt-3">
          <div
            v-for="item in faq"
            :key="item.id"
            :ref="`faq${item.id}`"
            class="faq-item"
          >
            <div
              class="header"
              @click="faqToggle(item)"
              :style="[item.showAnswer ? { border: 'none' } : '']"
            >
              <p>
                {{ item.title }}
              </p>
              <font-awesome-icon
                v-show="!item.showAnswer"
                :icon="['fas', 'plus']"
              />
              <font-awesome-icon
                v-show="item.showAnswer"
                :icon="['fas', 'minus']"
              />
            </div>
            <div class="body">
              <p>
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer></Footer>
  </div>
</template>

<script>
import http from "@/http";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default {
  name: "Faq",
  data() {
    return {
      faq: [],
      loadingFull: true,
    };
  },
  components: {
    Header,
    Footer,
  },
  created() {
    http
      .get("faq/list")
      .then((response) => {
        this.faq = response.data.list;
      })
      .catch((err) => {
        console.log(err);
      });

    const myLink = document.createElement("link");
    myLink.href =
      "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css";
    myLink.rel = "stylesheet";
    document.getElementsByTagName("head")[0].append(myLink);

    this.loadingFull = false;
  },
  methods: {
    faqToggle(item) {
      if (!item.showAnswer) {
        item.showAnswer = false;
      }
      const faqItem = this.$refs[`faq${item.id}`][0];
      const height = faqItem.querySelector(".body p").offsetHeight;

      item.showAnswer = !item.showAnswer;
      faqItem.querySelector(".body").style.height = item.showAnswer
        ? `${height}px`
        : "0px";
    },
  },
};
</script>
<style lang="scss" scoped>
* {
  color: #252525;
}
section.offers {
  margin-top: 90px;
}

.faq-holder {
  position: relative;
  width: 100%;
  margin-top: 35px;
  @media screen and (max-width: 720px) {
    margin-top: 15px;
  }
  .faq-item {
    position: relative;
    width: 100%;
    .header {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 24px 16px 0;
      border-bottom: 1px solid #eee;
      cursor: pointer;
      p {
        font-size: 1.4em;
        color: #555;
        @media screen and (max-width: 720px) {
          font-size: 1.4em;
          margin-right: 15px;
        }
      }
      svg {
        font-weight: bold;
        font-size: 1.2em;
      }
    }
    .body {
      position: relative;
      width: 100%;
      border-bottom: 1px solid #dedede;
      overflow: hidden;
      height: 0;
      transition: height 0.3s ease;
      p {
        position: relative;
        font-size: 1.4em;
        font-weight: lighter;
        line-height: 1.4em;
        padding: 16px 24px 24px 24px;
        @media screen and (max-width: 720px) {
          padding-left: 0;
        }
      }
    }
  }
}
</style>
