<template>
  <div id="app">
    <transition leave-active-class="animated fadeOutDown">
      <div v-show="!termsAccepted" class="terms-acceptance">
        <div class="container">
          <h1>POLÍTICA DE PRIVACIDADE E PROTEÇÃO DE DADOS</h1>
          <p>
            Para otimizar sua experiência durante a navegação, fazemos uso de
            cookies. Ao continuar no site, consideramos que você está de acordo
            com nossa
            <a href="#/politicas-de-privacidade">Política de Cookies</a>.
          </p>
          <button class="btn bg-primary" @click="acceptTerms">Ok</button>
        </div>
      </div>
    </transition>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      termsAccepted: false,
    };
  },
  created() {
    if (localStorage.cookiesTerms) {
      this.termsAccepted = true;
    } else {
      this.termsAccepted = false;
    }
  },
  methods: {
    acceptTerms() {
      localStorage.cookiesTerms = "accepted";
      this.termsAccepted = true;
    },
  },
};
</script>

<style lang="scss">
:root {
  --primary: #ffc907;
  --secondary: #0086fb;
  --gray1: #f7f7f7;
  --silver1: #eaeaea;
  --dark1: #000;
  --dark2: #1a1a1a;
  --dark3: #202020;
}

.color-primary {
  color: var(--primary) !important;
}

.color-secondary {
  color: var(--secondary) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.desaturate {
  filter: saturate(0) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

*:not(path) {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  text-decoration: none;

  box-sizing: border-box;
}

html,
body,
header,
section,
nav,
canvas,
video,
div,
span,
ul,
li,
a,
article,
h1,
h2,
h3,
h4,
h5,
p {
  font-size: 13px;
  font-family: fontRegular;
  color: #111;
}

@font-face {
  font-family: "fontRegular";
  src: url("./assets/fonts/din-medium.otf");
  font-display: swap;
}

@font-face {
  font-family: "fontRegular2";
  src: url("./assets/fonts/Roboto-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "fontLight";
  src: url("./assets/fonts/Roboto-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: "fontMedium";
  src: url("./assets/fonts/Roboto-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "fontBold";
  src: url("./assets/fonts/Roboto-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "fontBlack";
  src: url("./assets/fonts/Roboto-Black.ttf");
  font-display: swap;
}

.container.intern-absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.terms-acceptance {
  position: fixed;
  width: 100vw;
  padding: 25px 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #000000fb;
  z-index: 99999999;
}

.terms-acceptance h1 {
  color: #fff;
  font-size: 1.3em;
  font-family: fontBold;
}

.terms-acceptance p {
  color: #fff;
  margin-top: 15px;
}

.terms-acceptance a {
  color: #ffd84e;
  font-size: 1em;
}

.terms-acceptance button {
  margin-top: 20px;
  min-width: 200px;
}

html,
body {
  min-height: 100vh;
  min-width: 100vw;
}

.clearfix {
  clear: both !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-black {
  background-color: #000 !important;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap !important;
}

.text-center {
  text-align: center;
}

ul {
  list-style: none;
}

section {
  position: relative;
  width: 100vw;
  padding: 65px 0;
}

.bg-cover {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 99;
}

.loading1 {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loading1 div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: var(--primary);
  animation: loading1 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.loading1 div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.loading1 div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.loading1 div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes loading1 {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.arrow-up {
  position: absolute;
  top: -25px;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: 26px solid transparent;
  border-right: 26px solid transparent;
  border-bottom: 26px solid var(--primary);
}

.arrow-left {
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: -25px;
  margin: 0 auto;
  border-top: 26px solid transparent;
  border-right: 26px solid var(--primary);
  border-bottom: 26px solid transparent;
  transform: translateY(-50%);
}

.section-title {
  position: relative;
  font-weight: bold;
  color: var(--primary);
  font-size: 2.6em;
}

.section-title.black-line::after {
  background-color: #000;
}

.section-title.white-line::after {
  background-color: #fff;
}

.section-title.line-130::after {
  left: 170px;
}

.section-title.line-130::before {
  left: 210px;
}

.section-title.line-160::after {
  left: 160px;
}

.section-title.line-160::before {
  left: 230px;
}

.section-title.black-arrows::before {
  filter: saturate(0) brightness(0);
}

.section-title::after {
  position: absolute;
  content: "";
  width: 100vw;
  height: 1px;
  background-color: #fff;
  bottom: 0;
  left: 290px;
}

.swiper-pagination-bullet-active {
  background: #000 !important;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: -2px;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: -2px;
}

.section-title::before {
  position: absolute;
  content: "";
  width: 208px;
  height: 50px;
  background: url("./assets/images/arrows.png");
  background-repeat: no-repeat;
  background-size: cover;
  left: 320px;
  bottom: -25px;
}

button.btn {
  position: relative;
  padding: 12px 16px;
  border-radius: 3px;
  margin-top: 25px;
  background-color: #fff;
  box-shadow: 0 9px 10px -4px rgba(0, 0, 0, 0.15);
  transition: color 0.3s ease, background-color 0.2s ease;
  cursor: pointer;
}

button.btn:hover {
  background: #000;
  color: #fff;
}

.app-btn {
  position: relative;
  padding: 20px 38px;
  border-radius: 12px;
  background-color: var(--dark3);
  margin: 25px auto 0 auto;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 9px 10px -4px rgba(0, 0, 0, 0.15);
}

.app-btn span {
  font-family: fontMedium;
  font-size: 1.2em;
  color: #fff;
  text-align: center;
  transition: color 0.3s ease;
}

.app-btn:hover {
  background-color: var(--primary);
}

.app-btn:hover span {
  color: #000;
}

.loading2 {
  animation-duration: 0.75s;
  animation-iteration-count: infinite;
  animation-name: rotate-forever;
  animation-timing-function: linear;
  height: 20px;
  width: 20px;
  border: 2px solid var(--primary);
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
}

.loading2.black {
  border: 2px solid #000;
  border-right-color: transparent;
}

.full-loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.page-anim1 {
  animation-name: anim1;
  animation-duration: 0.3s;
}

.page-anim2 {
  animation-name: anim2;
  animation-duration: 0.3s;
}

.page-anim3 {
  animation-name: anim3;
  animation-duration: 0.3s;
}

@keyframes anim1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes anim2 {
  from {
    opacity: 0;
    transform: translateX(17vw);
  }
  to {
    opacity: 1;
    transform: translateX(0vw);
  }
}

@keyframes anim3 {
  from {
    opacity: 0;
    transform: translateY(25vw);
  }
  to {
    opacity: 1;
    transform: translateY(0vw);
  }
}
</style>
