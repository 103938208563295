<template>
  <div>
    <h1 class="register tablet-hidden mobile-hidden">CADASTRO</h1>
    <p class="mt-1 intro">
      Para realizar o seu cadastro, você precisará fornecer:
    </p>

    <p class="mt-2 subtitle">Dados Pessoais</p>
    <ul class="mt-1 list">
      <li>Anexar uma cópia da CNH</li>
      <li>Anexar um comprovante de endereço</li>
      <li>
        Anexar um comprovante de renda, extrato bancário ou imposto de renda
      </li>
      <li>RG e CPF</li>
      <li>Endereço completo</li>
    </ul>

    <p class="mt-2 subtitle">Dados Bancários</p>
    <ul class="mt-1 list">
      <li>Banco, agência e conta</li>
      <li>Tempo como cliente</li>
      <li>Nome e telefone do seu gerente</li>
    </ul>

    <p class="mt-2 subtitle">Dados Profissionais</p>
    <ul class="mt-1 list">
      <li>Empresa</li>
      <li>CEP e endereço da empresa</li>
      <li>Telefone</li>
      <li>Nome de um responsável</li>
      <li>Tempo de empresa</li>
    </ul>

    <p class="mt-3 subtitle">
      Estou com tudo em mãos e quero cadastrar!
    </p>
  </div>
</template>
<script>
export default {
  name: "RegisterInfo"
};
</script>
<style scoped>
h1.register {
  font-size: 2em;
  text-align: center;
  color: var(--primary);
}

p.intro {
  font-size: 1.4em;
  text-align: center;
}

.subtitle {
  padding-top: 15px;
  border-top: 1px dashed var(--primary);
  font-family: fontMedium;
  font-size: 1.3em;
}

ul.list {
  position: relative;
}

ul.list li {
  font-family: fontRegular2;
  font-size: 1.2em;
}

ul.list li::before {
  position: relative;
  content: "• ";
  color: var(--primary);
  font-size: 1.6em;
  top: 4px;
}

.subtitle.mt-3 {
  padding-top: 35px;
}
</style>
