import http from "@/http";
export default {
  validateFullName: (fullName) => {
    const fullNameRegex =
      /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+([A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]*)\s[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+$/;
    return fullNameRegex.test(fullName);
  },
  validateEmail: (email) => {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*\.\w+$/;
    return emailRegex.test(email);
  },
  formatDate: (date) => {
    const day = date.split("/")[0];
    const month = date.split("/")[1];
    const year = date.split("/")[2];
    return `${year}-${month}-${day}`;
  },
  validateCep: async (cep) => {
    const myCep = cep.replace(/[^\d]/g, "");
    let returnObj;
    const promise = new Promise((resolve, reject) => {
      if (myCep.length != 8) {
        return "CEP inválido";
      } else {
        http
          .get(`https://viacep.com.br/ws/${myCep}/json/`)
          .then((response) => {
            returnObj = {
              uf: response.data.uf,
              city: response.data.localidade,
              neighborhood: response.data.bairro,
              address: response.data.logradouro,
            };
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            resolve(returnObj);
          });
      }
    });
    const result = await promise;
    return result;
  },
  cardFlag: (cardnumber) => {
    const newCardnumber = cardnumber.replace(/[^0-9]+/g, "");

    const cards = {
      visa: /^4[0-9]{12}(?:[0-9]{3})/,
      mastercard: /^5[1-5][0-9]{14}/,
      diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
      amex: /^3[47][0-9]{13}/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
      hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
      elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}/,
      aura: /^(5078\d{2})(\d{2})(\d{11})$/,
    };

    for (const flag in cards) {
      if (cards[flag].test(newCardnumber)) {
        return flag;
      }
    }

    return false;
  },
  validateCPF: (cpf) => {
    const cpfInvalidos = [
      "00000000000",
      "11111111111",
      "22222222222",
      "33333333333",
      "44444444444",
      "55555555555",
      "66666666666",
      "77777777777",
      "88888888888",
      "99999999999",
    ];

    const newCpf = cpf.replace(/[^\d]+/g, "");
    if (newCpf == "") return false;
    if (newCpf.length != 11) return false;
    if (cpfInvalidos.includes(newCpf)) return false;

    let add = 0;
    for (let i = 0; i < 9; i++) add += parseInt(newCpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) rev = 0;
    if (rev != parseInt(newCpf.charAt(9))) return false;
    // Valida 2o digito
    add = 0;
    for (let i = 0; i < 10; i++) add += parseInt(newCpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) rev = 0;
    if (rev != parseInt(newCpf.charAt(10))) return false;

    return true;
  },
};
